import React, { Component } from 'react';
import {
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    NavLink
} from 'reactstrap';
import PropTypes from 'prop-types';

class UserMenu extends Component {
    constructor() {
        super();

        this.state = {
            menuOpened: false,
            redirect: false,
        };

        this.handleToggle = this.handleToggle.bind(this);
    }

    handleToggle() {
        this.setState({
            menuOpened: !this.state.menuOpened
        });
    }

    render() {
        const { userDetail } = this.props;
        const { menuOpened } = this.state;

        return (
            <Dropdown isOpen={menuOpened} toggle={this.handleToggle}>
                <DropdownToggle caret>
                    <img src="/images/img_avatar.png" alt="Avatar" className="avatar" />
                    &nbsp;{ `${userDetail.firstname} ${userDetail.lastname}` }
                </DropdownToggle>
                <DropdownMenu>
                    <DropdownItem><NavLink href="/account" >Akun ku</NavLink></DropdownItem>
                    <DropdownItem><NavLink href="/account/order" >Pesanan ku</NavLink></DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem><NavLink onClick={this.props.handleLogout}>Keluar</NavLink></DropdownItem>
                </DropdownMenu>
            </Dropdown>
        )
    }
}

UserMenu.propTypes = {
    userDetail: PropTypes.object.isRequired,
    handleLogout: PropTypes.func.isRequired,
  };

export default UserMenu;
