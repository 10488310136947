import React, { Component } from 'react';
import {
    Button,
    Row,
    Col,
    Form,
    FormGroup,
    FormFeedback,
    Label,
    Input
} from 'reactstrap';
import PropTypes from 'prop-types';
import validator from 'validator';
import pick from 'lodash.pick';
import FormValidator from '../../utils/FormValidator';

class ChangePassword extends Component {
    constructor(props) {
        super(props);

        this.validator = new FormValidator([
            {
                field: 'email',
                method: validator.isEmpty,
                validWhen: false,
                message: 'Silahkan isi email'
            },
            {
                field: 'email',
                method: validator.isEmail,
                validWhen: true,
                message: 'Silahkan isi format email yang benar'
            },
            {
                field: 'password',
                method: validator.isEmpty,
                validWhen: false,
                message: 'Silahkan isi password'
            },
            {
                field: 'password',
                method: validator.isLength,
                args: [{ min: 6 }],
                validWhen: true,
                message: 'Password minimal 6 karakter'
            },
            {
                field: 'password_confirmation',
                method: validator.isEmpty,
                validWhen: false,
                message: 'Silahkan isi data konfirmasi password'
            },
            {
                field: 'password_confirmation',
                method: this.passwordMatch,   // notice that we are passing a custom function here
                validWhen: true,
                message: 'Password dan Konfirmasi Password tidak sama'
            },
        ]);

        this.state = {
            email: props.user.email,
            password: '',
            password_confirmation: '',
            validation: this.validator.valid()
        };

        this.resetPassword = this.resetPassword.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        this.setState({[event.target.name]: event.target.value});
    }

    passwordMatch = (confirmation, state) => (state.password === confirmation)

    resetPassword() {
        const { handleSubmit } = this.props;
        const validation = this.validator.validate(this.state);

        this.setState({
            validation: validation
        });

        if (validation.isValid) {
            const payload = pick(this.state, ['email', 'password', 'confirm_password']);

            handleSubmit(payload);
        }
    };

    render() {
        const { validation } = this.state;

        return (
            <Row>
                <Col sm="12">
                    <Form className="Left">
                        <FormGroup>
                            <Label for="email">Email</Label>
                            <Input type="email" name="email" id="email" disabled placeholder="example@ditenun.com"
                                value={this.state.email}
                                invalid={validation.email.isInvalid}
                                onChange={this.handleChange} />
                            <FormFeedback>{validation.email.message}</FormFeedback>
                        </FormGroup>
                        <FormGroup>
                            <Label for="password">Password</Label>
                            <Input type="password" name="password" id="password" placeholder="password"
                                value={this.state.password}
                                invalid={validation.password.isInvalid}
                                onChange={this.handleChange} />
                            <FormFeedback>{validation.password.message}</FormFeedback>
                        </FormGroup>
                        <FormGroup>
                            <Label for="password_confirmation">Konfirmasi Password</Label>
                            <Input type="password" name="password_confirmation" id="register_confirm_password" placeholder="confirm password"
                                onChange={this.handleChange}
                                invalid={validation.password_confirmation.isInvalid}
                                value={this.state.password_confirmation} />
                            <FormFeedback>{validation.password_confirmation.message}</FormFeedback>
                        </FormGroup>
                    </Form>
                    <Button onClick={this.resetPassword}>Update</Button>
                </Col>
            </Row>
        )
    }
}
ChangePassword.propTypes = {
    user: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
};
export default ChangePassword;