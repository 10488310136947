import * as types from '../constants/order';

const initialState = {
    list: [],
}

export default function (state = initialState, action) {
    switch (action.type) {
        case types.GET_ALL_ORDER_SUCCESS:
            return {
                ...state,
                list: action.payload.message,
            };

        default:
            return state;
    }
}
