/**
 * @module Actions/Order
 * @desc Order Actions
 */

import * as types from '../constants/order';

export const getCustomerOrder = () => {
    return {
      type: types.GET_ALL_ORDER,
    }
};

export const confirmOrder = (orderId, payload) => {
    return {
      type: types.CONFIRM_ORDER,
      params: {
          order_id: orderId
      },
      payload,
    }
};
