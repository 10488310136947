/**
 * @module Sagas/User
 * @desc User
 */
import { call, put, select } from 'redux-saga/effects';
import update from 'immutability-helper';

import CartAPI from '../utils/api/cart';
import { handleError } from '../actions/app';
import * as types from '../constants/cart';

/**
 * Cart Summary
 */
export function* getCartSummary() {
    try {
        let { detail } = yield select(state => state.user);

        const result = yield call(CartAPI.cartSummary, detail.id, detail.api_token);

        yield put({
            type: types.GET_CART_SUMMARY_SUCCESS,
            payload: result,
        });
    } catch (err) {
        yield put(handleError(err));

        yield put({
            type: types.GET_CART_SUMMARYT_FAILURE,
            payload: err,
        });
    }
}

export function* processCheckout({ payload }) {
    try {
        let { detail } = yield select(state => state.user);

        const result = yield call(CartAPI.processCheckout, detail.id, payload.total, payload.promo_id, detail.api_token);

        yield put({
            type: types.CHECKOUT_CART_SUCCESS,
            payload: result,
        });
    } catch (err) {
        yield put({
            type: types.CHECKOUT_CART_FAILURE,
            payload: err,
        });
    }
}

export function* increaseQuantity({ payload }) {
    try {
        let { detail } = yield select(state => state.user);
        let { cart } = yield select(state => state.cart);

        const index = cart.findIndex(function(item) {
            return item.id === payload.product_id;
        });

        const product = update(cart[index], { quantity: { $set: parseInt(cart[index].quantity) + parseInt(payload.quantity) }});

        yield call(CartAPI.updateCartDetail, payload.product_id, product, detail.api_token);

        yield put({
            type: types.INCREASE_CART_QUANTITY_SUCCESS,
            payload: payload,
        });
    } catch (err) {
        yield put({
            type: types.INCREASE_CART_QUANTITY_FAILURE,
            payload: err,
        });
    }
}

export function* decreaseQuantity({ payload }) {
    try {
        let { cart, total } = yield select(state => state.cart);

        if (total > 0) {
            let { detail } = yield select(state => state.user);

            const index = cart.findIndex(function(item) {
                return item.id === payload.product_id;
            });

            const product = update(cart[index], { quantity: { $set: parseInt(cart[index].quantity) - parseInt(payload.quantity) }});

            yield call(CartAPI.updateCartDetail, payload.product_id, product, detail.api_token);

            yield put({
                type: types.DECREASE_CART_QUANTITY_SUCCESS,
                payload: payload,
            });
        }
    } catch (err) {
        yield put({
            type: types.DECREASE_CART_QUANTITY_FAILURE,
            payload: err,
        });
    }
}


export function* removeFromCart({ payload }) {
    try {
        let { detail } = yield select(state => state.user);

        yield call(CartAPI.removeProductFromCart, payload.product_id,  detail.api_token);

        yield put({
            type: types.REMOVE_PRODUCT_FROM_CART_SUCCESS,
            payload: payload,
        });

    } catch (err) {
        yield put({
            type: types.REMOVE_PRODUCT_FROM_CART_FAILURE,
            payload: err,
        });
    }
}
