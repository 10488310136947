import React from 'react';
import { connect } from 'react-redux';
import { Container, Col, Row, Media, Jumbotron } from 'reactstrap';
import Spinner from '../common/Spinner';
import '../css/Product.css';
import Category from './Category';
// import { BASE_URL } from '../../Config';

import { allProduct, category } from '../../actions/product';

class Product extends React.Component{
    constructor(props){
        super(props);

        this.state = {
            loading: props.loading,
            products: [],
        };

        this.filterByCategory = this.filterByCategory.bind(this);
    }

    componentWillMount() {
        const { dispatch } = this.props;

        dispatch(allProduct());
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            loading: nextProps.loading,
        });
    }

    filterByCategory(id) {


        const { dispatch } = this.props;

        dispatch(category(id));
    }

    renderProductList() {
        if (this.props.products.length === 0) {
            return (
                <Jumbotron>
                    <h1 className="display-3">Tidak ada produk dengan kategori yang dipilih</h1>
                </Jumbotron>
            );
        }

        return this.props.products.map((product, i) => {
            return(
                <Col key={i} className="Product-column" lg="4" sm="4" md="4" xs="4">
                    <Media className="Product-content">
                        <a href={`product/${product.id}`} className="d-block mb-4 h-100" id_product={product.id}>
                        {/* http://178.128.106.214:8000 */}
                            {/* <img className="img-fluid" src={'http://api.ditenun.com'+product.thumbnail} alt={product.name} /> */}
                            <img className="img-fluid" src={product.thumbnail} alt={product.name} />
                            <p>{product.name}</p>
                        </a>
                    </Media>
                </Col>
            )
        })
    }

    render(){
        console.log('loading: ', this.state.loading);
        return(
            <div className="Ditenun-product">
                <section>
                    <Category
                        filterByCategory={this.filterByCategory}
                    />
                    <Container className="Product-list">
                        <Row>
                            { this.renderProductList() }
                            { this.state.loading
                                ? <Spinner />
                                : ''
                            }
                        </Row>
                    </Container>
                </section>
            </div>
        );
    }
}

// Subscribe component to redux store and merge the state into
 // component's props
 const mapStateToProps = ({ user, product }) => {
    return {
      isLoggedIn: user.isLoggedIn,
      products: product.products,
      loading: product.loading,
    }
};

export default connect(mapStateToProps)(Product);
