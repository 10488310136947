import request from './request';

export default {
    all() {
        const path = `product/allproduct`;
        const method = 'get';

        return request.process(method, path);
    },

    category(id) {
        const path = `category/getcategory/${id}`;
        const method = 'get';

        return request.process(method, path);
    },

    detail(id) {
        const path = `product/getproduct/${id}`;
        const method = 'get';

        return request.process(method, path);
    },

    images(id) {
        const path = `image/getproduct/${id}`;
        const method = 'get';

        return request.process(method, path);
    },

    addToCart(customerId, product, token, quantity=1) {
        const path = `cart/add_to_cart`;
        const method = 'post';
        const payload = {
            customer_id: customerId,
            product_id: product.id,
            quantity: quantity,
            description: product.product_description,
        };

        return request.process(method, path, payload, token);
    },
};
