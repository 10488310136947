/**
 * @namespace Constants
 * @desc Order constants
 */

  export const GENERATE_KRISTIK = 'GENERATE_KRISTIK';
  export const GENERATE_KRISTIK_SUCCESS = 'GENERATE_KRISTIK_SUCCESS';
  export const GENERATE_KRISTIK_FAILURE = 'GENERATE_KRISTIK_FAILURE';

  export const GET_ALL_KRISTIK = 'GET_ALL_KRISTIK';
  export const GET_ALL_KRISTIK_SUCCESS = 'GET_ALL_KRISTIK_SUCCESS';
  export const GET_ALL_KRISTIK_FAILURE = 'GET_ALL_KRISTIK_FAILURE';


  export const GENERATE_MOTIF = 'GENERATE_MOTIF';
  export const GENERATE_MOTIF_SUCCESS = 'GENERATE_MOTIF_SUCCESS';
  export const GENERATE_MOTIF_FAILURE = 'GENERATE_MOTIF_FAILURE';

  export const GET_ALL_MOTIF = 'GET_ALL_MOTIF';
  export const GET_ALL_MOTIF_SUCCESS = 'GET_ALL_MOTIF_SUCCESS';
  export const GET_ALL_MOTIF_FAILURE = 'GET_ALL_MOTIF_FAILURE';