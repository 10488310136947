import React from 'react';
import PropTypes from 'prop-types';
import { Button, Nav, NavItem, NavLink } from 'reactstrap';

class SideMenu extends React.Component{
    // constructor(props) {
    //     super(props);
    // }

    isActive(item){
        const { location } = window;

        return location.pathname === `/${item}`
    }

    render() {
        return (
            <div className="List-cart">
                <div>
                    <img className="img_profile" src="/images/img_avatar.png" alt=""/>
                    {`${this.props.user.firstname} ${this.props.user.lastname}`}
                    <hr />
                    <Nav vertical>
                        <NavItem active={this.isActive('account/update')}>
                            <NavLink className="text-left"
                                href="/account/update">Perbaharui data</NavLink>
                        </NavItem>
                        <NavItem active={this.isActive('account/order')}>
                            <NavLink className="text-left"
                                href="/account/order">Daftar Pemesanan</NavLink>
                        </NavItem>
                        <NavItem active={this.isActive('account/settings')}>
                            <NavLink className="text-left"
                                href="/account/settings">Reset Password</NavLink>
                        </NavItem>
                    </Nav>

                    <hr />
                    <Button className="link1" onClick={() =>this.props.handleLogout()} >Keluar</Button>
                </div>
            </div>
        )
    }
}

SideMenu.propTypes = {
    user: PropTypes.object.isRequired,
    handleLogout: PropTypes.func.isRequired,
};

export default SideMenu;
