/**
 * @module Actions/Profile
 * @desc Profile Actions
 */

import * as types from '../constants/profile';

export const showProfile = (id) => {
    return {
      type: types.GET_PROFILE_PENENUN,
      params: {
        profile_id: id,
    }
    }
};


export const allProfile = () => {
  return {
    type: types.GET_ALL_PROFILE_PENENUN,
    
  }
};

