import React from 'react';
import { Container, Row, Col } from 'reactstrap';

class Work extends React.Component{
    render() {
        return(
            <div className="Work Mobile-hidden Tab-hidden">
                <section>
                    <Container>
                        <Row className="Work-title">
                            <Col>
                                <h1>Bagaimana diTenun Bekerja?</h1>
                            </Col>
                        </Row>
                        <Row className="Work-content">
                            <Col xs="6" sm="4" >
                                <h4>Ciptakan Motif Baru</h4>
                                <p>
                                    Kami menggunakan variasi motif dari tenun tradisional yang sudah ada dan bahkan motif baru yang dihasilkan oleh software DiTenun
                                </p>
                            </Col>
                            <Col xs="6" sm="4" >
                                <h4>Material kami</h4>
                                <p>
                                    Kain Tenun Nasantara yang dikerjakan secara konvensional dan menggunakan alat tenun bukan mesin. Kami bekerjasama dengan perajin tenun tradisional
                                </p>
                            </Col>
                            <Col xs="6" sm="4" >
                                <h4>Penelitian yang mendalam</h4>
                                <p>
                                    Kami meneliti sendiri sistem mesin pembelajar untuk mengklasiffikasikan tenun Nusantara, menghasilkan motif baru dengan cepat dan otomati. Kami juga menemui penenun secara langsung guna menghasilkan sistem yang akurat.
                                </p>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </div>
        );
    }
}

export default Work;