import update from 'immutability-helper';
import * as types from '../constants/product';
import * as cartTypes from '../constants/cart';
import * as userTypes from '../constants/user';

const initialState = {
    cart: [],
    total: 0,
    checkout: false,
}

// Handles user related actions
export default function (state = initialState, action) {
    switch (action.type) {
        case types.ADD_PRODUCT_TO_CART_SUCCESS:
            return {
                ...state,
                total: state.total + 1,
            };

        case cartTypes.GET_CART_SUMMARY_SUCCESS:
            return {
                ...state,
                cart: action.payload.message,
                checkout: false,
                total: action.payload.message.reduce((acc, curr) => {
                    return acc + parseInt(curr.quantity);
                }, 0),
            };

        case cartTypes.INCREASE_CART_QUANTITY_SUCCESS:
            const data = state.cart;
            const productIndex = data.findIndex(function(item) {
                return item.id === action.payload.product_id;
            });

            const updatedProduct = update(data[productIndex], { quantity: { $set: parseInt(data[productIndex].quantity) + parseInt(action.payload.quantity) }})

            const newCart = update(data, {
                $splice: [[productIndex, 1, updatedProduct]]
            });

            return {
                ...state,
                total: state.total + parseInt(action.payload.quantity),
                cart: newCart,
            };

        case cartTypes.DECREASE_CART_QUANTITY_SUCCESS:
            if (state.total > 0) {
                const data = state.cart;
                const productIndex = data.findIndex(function(item) {
                    return item.id === action.payload.product_id;
                })

                const updatedProduct = update(data[productIndex], { quantity: { $set: parseInt(data[productIndex].quantity) - parseInt(action.payload.quantity) }})

                const newCart = update(data, {
                    $splice: [[productIndex, 1, updatedProduct]]
                });

                return {
                    ...state,
                    total: state.total - parseInt(action.payload.quantity),
                    cart: newCart,
                };
            }

            return state

        case cartTypes.REMOVE_PRODUCT_FROM_CART_SUCCESS:
            const index = state.cart.findIndex(function(item) {
                return item.id === action.payload.product_id;
            })

            const product = state.cart[index];

            return {
                ...state,
                cart: state.cart.filter((_, i) => i !== index),
                total: state.total - product.quantity
            };

        case cartTypes.CHECKOUT_CART_SUCCESS:
            return {
                ...initialState,
                checkout: true,
            };

        case userTypes.USER_LOGOUT_SUCCESS:
            return {
                ...initialState,
            };

        default:
            return state;
    }
}
