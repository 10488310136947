import { takeLatest } from 'redux-saga/effects';

import { login, logout, register, resetPassword, updateProfile } from './user';
import { allProduct, category, detail, images, addToCart } from './product';
import { getCartSummary, processCheckout, increaseQuantity, decreaseQuantity, removeFromCart } from './cart';
import { getCustomerAddress, addCustomerAddress } from './address';
import { getCustomerOrder, confirmOrder } from './order';
import { clearNotification } from './notification';
import { generateKristik, getAllKristik, generateMotif, getAllMotif } from './generator';
import {showProfile, allProfile} from './profile';

import * as userTypes from '../constants/user';
import * as productTypes from '../constants/product';
import * as cartTypes from '../constants/cart';
import * as addressTypes from '../constants/address';
import * as orderTypes from '../constants/order';
import * as notificationTypes from '../constants/notification';
import * as generatorTypes from '../constants/generator';
import * as profileTypes from '../constants/profile';

// Watches for SEARCH_MEDIA_REQUEST action type asynchronously
export function* watchLogin() {
    yield takeLatest(userTypes.USER_LOGIN, login);
}

export function* watchLogout() {
    yield takeLatest(userTypes.USER_LOGOUT, logout);
}

export function* watchRegister() {
    yield takeLatest(userTypes.USER_REGISTER, register);
}

export function* watchResetPassword() {
    yield takeLatest(userTypes.USER_RESET_PASSWORD, resetPassword);
}

export function* watchUpdateProfile() {
    yield takeLatest(userTypes.USER_UPDATE_PROFILE, updateProfile);
}

export function* watchAllProduct() {
    yield takeLatest(productTypes.GET_ALL_PRODUCT, allProduct);
}

export function* watchProductsByCategory() {
    yield takeLatest(productTypes.GET_PRODUCT_BY_CATEGORY, category);
}

export function* watchProductDetail() {
    yield takeLatest(productTypes.GET_PRODUCT_DETAIL, detail);
}

export function* watchProductImages() {
    yield takeLatest(productTypes.GET_PRODUCT_IMAGES, images);
}

export function* watchAddToCart() {
    yield takeLatest(productTypes.ADD_PRODUCT_TO_CART, addToCart);
}

export function* watchCartSummary() {
    yield takeLatest(cartTypes.GET_CART_SUMMARY, getCartSummary);
}

export function* watchAddCartQuantity() {
    yield takeLatest(cartTypes.INCREASE_CART_QUANTITY, increaseQuantity);
}

export function* watchDecreaseCartQuantity() {
    yield takeLatest(cartTypes.DECREASE_CART_QUANTITY, decreaseQuantity);
}

export function* watchRemoveProductCart() {
    yield takeLatest(cartTypes.REMOVE_PRODUCT_FROM_CART, removeFromCart);
}

export function* watchGetAddress() {
    yield takeLatest(addressTypes.GET_CUSTOMER_ADDRESS, getCustomerAddress);
}

export function* watchAddAddress() {
    yield takeLatest(addressTypes.ADD_CUSTOMER_ADDRESS, addCustomerAddress);
}

export function* watchCheckoutCart() {
    yield takeLatest(cartTypes.CHECKOUT_CART, processCheckout);
}

export function* watchGetAllOrder() {
    yield takeLatest(orderTypes.GET_ALL_ORDER, getCustomerOrder);
}

export function* watchConfirmOrder() {
    yield takeLatest(orderTypes.CONFIRM_ORDER, confirmOrder);
}

export function* watchNotification() {
    yield takeLatest(notificationTypes.EMIT_NOTIFICATION, clearNotification);
}

export function* watchGenerateKristik() {
    yield takeLatest(generatorTypes.GENERATE_KRISTIK, generateKristik);
}

export function* watchGetAllKristik() {
    yield takeLatest(generatorTypes.GET_ALL_KRISTIK, getAllKristik);
}

export function* watchGenerateMotif() {
    yield takeLatest(generatorTypes.GENERATE_MOTIF, generateMotif);
}

export function* watchGetAllMotif() {
    yield takeLatest(generatorTypes.GET_ALL_MOTIF, getAllMotif);
}

export function* watchShowProfile() {
    yield takeLatest(profileTypes.GET_PROFILE_PENENUN, showProfile);
}

export function* watchAllProfile() {
    yield takeLatest(profileTypes.GET_ALL_PROFILE_PENENUN, allProfile);
}
