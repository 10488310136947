import React from 'react'
import {
     Col,
     Row,
     Button,
} from 'reactstrap';
import AddPhotoAlternate from '@material-ui/icons/AddPhotoAlternate';
import PropTypes from 'prop-types';

const RandomMotif = (props) => {
    return (
        <Row>
            <Col sm="12" md={{ size: 8, offset: 2 }}>
                <div className="box box-solid random">
                    <div className="box-body">
                        { props.image
                            ? <img src={props.image} alt="upload placeholder"/>
                            : <AddPhotoAlternate className="icon-large"/>
                        }
                    </div>
                    <div className="box-footer">
                        <Button className="button3" onClick={props.imageChange}>
                            Generate Random Kristik
                        </Button>
                    </div>
                </div>
            </Col>
        </Row>
    );
}

RandomMotif.propTypes = {
    image: PropTypes.string.isRequired,
    imageChange: PropTypes.func.isRequired,
  };
  
export default RandomMotif;
