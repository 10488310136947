import React from 'react';
import { Container, Col, Row, Media, Button} from 'reactstrap';
import Delete from '@material-ui/icons/Delete';
import Plus from '@material-ui/icons/AddCircleOutline';
import Minus from '@material-ui/icons/RemoveCircleOutline';

class UI extends React.Component{
    render(){
        return(
            <section>
                <Container>
                <h1>Heading 1</h1>
                <h2>Heading 2</h2>
                <h3>Heading 3</h3>
                <h4>Heading 4</h4>
                <h5>Heading 5</h5>
                </Container>
                <hr />
                <Container className="Ditenun">
                    <h2>Links</h2>
                    <a className="link1" href="/">link1</a>
                    <a className="link2" href="/">link2</a>
                    <a className="link3" href="/">link3</a>
                    <a className="link4" href="/">link4</a>
                    <a className="link5" href="/">link5</a>
                </Container>
                <hr />
                <Container>
                    <h2>Buttons</h2>
                    <Button className="button1">Button1</Button>
                    <Button className="button2">Button2</Button>
                    <Button className="button3">Button3</Button>
                    <Button className="button4">Button4</Button>
                    <Button className="button5">Button5</Button>
                </Container>
                <hr />
                <Container>
                    <div className="Ditenun-list-cart">
                        <h1>List Cart</h1>
                        <Row>
                            <Col>
                                <Media className="List-cart">
                                    <Media>
                                        <Media className="generate" object src="http://ditenun.com/images/product-1.png" alt="Generic placeholder image" />
                                    </Media>
                                    <Media body>
                                        <Media href="#"> Media heading </Media>
                                        RP. 400.4000.
                                    </Media>
                                    <Media right>
                                        <Media> <Minus /> &nbsp; 2 &nbsp; <Plus /> &nbsp; <Delete />  </Media>
                                    </Media>
                                </Media>
                                <Media className="List-cart">
                                    <Media>
                                        <Media  className="generate" object src="http://ditenun.com/images/product-1.png" alt="Generic placeholder image" />
                                    </Media>
                                    <Media body>
                                        <Media href="#"> Media heading </Media>
                                        RP. 400.4000.
                                    </Media>
                                    <Media right>
                                        <Media> <Minus /> &nbsp; 2 &nbsp; <Plus /> &nbsp; <Delete />  </Media>
                                    </Media>
                                </Media>
                                <Media className="List-cart">
                                    <Media>
                                        <Media className="generate" object src="http://ditenun.com/images/product-1.png" alt="Generic placeholder image" />
                                    </Media>
                                    <Media body>
                                        <Media href="#"> Media heading </Media>
                                        RP. 400.4000.
                                    </Media>
                                    <Media right>
                                        <Media> <Minus /> &nbsp; 2 &nbsp; <Plus /> &nbsp; <Delete />  </Media>
                                    </Media>
                                </Media>
                                <hr />
                                <Media className="List-cart">
                                    <Media body>
                                        <Media><strong>Alamat Pengiriman</strong></Media>
                                        <hr />
                                        <Media>Masto Sitorus</Media>
                                        <Media>Material icons are delightful, beautifully crafted symbols for common actions and items.</Media>
                                    </Media>
                                    <Media right href="#">
                                        <Media > ubah alamat </Media>
                                    </Media>
                                </Media>
                                <hr />
                                <Media className="List-cart">
                                    <Media body>
                                    <strong>Note: Pengiriman akan melalui kurir: JNE, TiKi</strong>
                                    </Media>
                                </Media>
                            </Col>
                            <Col xs="6" sm="4">
                                <Media className="List-cart Ditenun">

                                    <Media body>
                                        <Media><strong>Ringkasan Belanja</strong></Media>
                                        <hr />
                                        <Media> total: Rp. 1232.123123</Media>
                                        <hr />
                                        <a href="/checkput" >Bayar</a>
                                    </Media>
                                </Media>
                                <Media className="List-cart">
                                    <Media body>
                                        <Media><strong>Transfer ke Bank: PT. Ditenun</strong></Media>
                                        <hr />
                                        <Media>Mandiri: 12312.12312.312</Media>
                                        <Media>BCA: 12312.12312.312</Media>
                                        <Media>BTPN: 12312.12312.312</Media>
                                    </Media>
                                </Media>
                            </Col>
                        </Row>
                    </div>
                </Container>
                <hr/>
                <Container>
                <div className="Ditenun-list-cart">
                        <h1>List Order</h1>
                        <Row>
                            <Col>
                                <Media className="List-cart">
                                    <Media>
                                        <Media className="generate" object src="http://ditenun.com/images/product-1.png" alt="Generic placeholder image" />
                                    </Media>
                                    <Media body>
                                        <Media href="#"> #12399232 </Media>
                                        10 - 30 Nov 2018
                                    </Media>
                                    <Media right>
                                        <Media><button type="button" className="btn btn-primary btn-sm">verifikasi</button> </Media>
                                    </Media>
                                </Media>
                                <Media className="List-cart">
                                    <Media>
                                        <Media className="generate" object src="http://ditenun.com/images/product-1.png" alt="Generic placeholder image" />
                                    </Media>
                                    <Media body>
                                        <Media href="#"> #12399232 </Media>
                                        10 - 30 Nov 2018
                                    </Media>
                                    <Media right>
                                        <Media> Selesai </Media>
                                    </Media>
                                </Media>
                                <Media className="List-cart">
                                    <Media>
                                        <Media className="generate" object src="http://ditenun.com/images/product-1.png" alt="Generic placeholder image" />
                                    </Media>
                                    <Media body>
                                        <Media href="#"> #12399232 </Media>
                                        10 - 30 Nov 2018
                                    </Media>
                                    <Media right>
                                        <Media> Selesai </Media>
                                    </Media>
                                </Media>
                                <Media className="List-cart">
                                    <Media>
                                        <Media className="generate" object src="http://ditenun.com/images/product-1.png" alt="Generic placeholder image" />
                                    </Media>
                                    <Media body>
                                        <Media href="#"> #12399232 </Media>
                                        10 - 30 Nov 2018
                                    </Media>
                                    <Media right>
                                        <Media> Selesai </Media>
                                    </Media>
                                </Media>
                            </Col>
                        </Row>
                    </div>
                </Container>
                <hr />
                <Container>
                    <h1>User Profile</h1>
                </Container>
                <hr />
                <Container>
                    <h1>User Profile</h1>
                </Container>
            </section>

        );
    }
}

export default UI;
