import * as types from '../constants/modal';

const initialState = {
    opened: false,
}

// Handles user related actions
export default function (state = initialState, action) {
    switch (action.type) {
        case types.TOGGLE_MODAL:
            return {
                ...state,
                opened: !state.opened,
            };

        default:
            return state;
    }
}
