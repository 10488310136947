import React from 'react';
import {
    Container,
    Row, Col,
    ListGroup,
    ListGroupItem
} from 'reactstrap';
import Carousel from 'nuka-carousel';
import { connect } from 'react-redux';
import { showProfile } from '../../actions/profile';

class ProfilePenenun extends React.Component{
    constructor(props){
        super(props);

        this.state = {
            loading: true,
            id: props.profileId,
        };

        this.handleImageLoaded = this.handleImageLoaded.bind(this);
    }

    componentWillMount() {
        const { dispatch, profileId } = this.props;

        dispatch(showProfile(profileId));
    }

    // https://github.com/FormidableLabs/nuka-carousel/issues/103
    handleImageLoaded() {
        setTimeout(() => {
            window.dispatchEvent(new Event('resize'));
        }, 0);
    }

    renderImages() {
        return <img onLoad={this.handleImageLoaded} src={this.props.profilePenenun.image_profile} alt="Profile Penenun" />
    }

    renderProfilePenenun() {
        const {profilePenenun} = this.props;
        // const profilePenenun = this.props.profilePenenun;
        // return this.props.products.map((product) => {
            return(
                <Col xs="12" sm="6" md="6" className="Product-description">
                    <h2>{profilePenenun.name}</h2>
                    <ListGroup className="list-solid text-left">
                        <ListGroupItem>
                            TTL: <span>{profilePenenun.ttl}</span>
                        </ListGroupItem>
                        <ListGroupItem>
                            No HP: <span>{profilePenenun.no_hp}</span>
                        </ListGroupItem>
                        <ListGroupItem>
                            Jenis Tenun: <span>{profilePenenun.jenis_tenun}</span>
                        </ListGroupItem>
                     </ListGroup>
                     <p>{profilePenenun.cerita}</p>
                </Col>
            )
        // });
    }

    render() {
        return(
            <div className="Product-detail">
                <section>
                    <Container>
                        <Row className="Product-detail-content">
                            <Col xs="12" sm="6">
                                <Carousel autoplay={ true }>
                                    { this.renderImages() }
                                </Carousel>
                            </Col>
                            { this.renderProfilePenenun() }
                        </Row>
                    </Container>
                </section>
            </div>
        );
    }
}

const mapStateToProps = ({ user, profile }) => {
    console.log(profile);
    return {
        isLoggedIn: user.isLoggedIn,
        profilePenenun: profile.profilePenenun
    }
};

export default connect(mapStateToProps)(ProfilePenenun);
