import React from 'react';
import { Container, Row, Col, Media } from 'reactstrap';

class Kristik extends React.Component{
    render() {
        return(
            <div className="Kristik Mobile-hidden Tab-hidden">
                <section>
                    <Container>
                        <Row>
                            <Col xs="6" className="Kristik-description Ditenun">
                                <h1>Kenapa kami membuat Ditenun?</h1>
                                <p>
                                    Tenun adalah unsur budaya yang berasal dari keterampilan yang diturunkan dari generasi ke generasi. Begitu banyak potensi ekonomi yang bisa dikembangkan dengan tenun. <br />
                                </p>
                                <p>
                                    Perajin tenun yang selama ini hanya mengerjakan tenun yang penggunaannya terbatas pada kesempatan seremonial adat, diharapkan dapat mengembangkan desain motif yang lebih modern dan popular sehingga dapat mengembangkan pemasaran dan meluaskan pemakaian tenun kepada orang awam. <br />
                                </p>
                                <a className="link1" href="/ditenun">selanjutnya</a>
                            </Col>
                            <Col xs="6">
                                <Media href="/pattern">
                                    <img className="Jumbotron-image" src= "/images/ditenun-mobile (2).jpeg" alt="kristik"/>
                                </Media>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </div>
        );
    }
}

export default Kristik;
