import React, { Component } from 'react';
import {
    Button,
    Row,
    Col,
    Form,
    FormGroup,
    FormFeedback,
    Label,
    Input
} from 'reactstrap';
import PropTypes from 'prop-types';
import validator from 'validator';
import pick from 'lodash.pick';
import FormValidator from '../../utils/FormValidator';

class RegisterForm extends Component {
    constructor(props) {
        super(props);

        this.validator = new FormValidator([
            {
                field: 'firstname',
                method: validator.isEmpty,
                validWhen: false,
                message: 'Silahkan isi Nama Depan'
            },
            {
                field: 'firstname',
                method: validator.isLength,
                args: [{ max: 50 }],
                validWhen: true,
                message: 'Nama Depan maksimal 50 karakter'
            },
            {
                field: 'lastname',
                method: validator.isEmpty,
                validWhen: false,
                message: 'Silahkan isi Nama Belakang'
            },
            {
                field: 'lastname',
                method: validator.isLength,
                args: [{ max: 50 }],
                validWhen: true,
                message: 'Nama Belakang maksimal 50 karakter'
            },
            {
                field: 'email',
                method: validator.isEmpty,
                validWhen: false,
                message: 'Silahkan isi email'
            },
            {
                field: 'email',
                method: validator.isEmail,
                validWhen: true,
                message: 'Silahkan isi format email yang benar'
            },
            {
                field: 'phone',
                method: validator.isEmpty,
                validWhen: false,
                message: 'Silahkan isi Nomor Handphone'
            },
            {
                field: 'address',
                method: validator.isEmpty,
                validWhen: false,
                message: 'Silahkan isi alamat'
            },
        ]);

        this.state = {
            username: props.user.username,
            firstname: props.user.firstname,
            lastname: props.user.lastname,
            email: props.user.email,
            phone: props.user.phone,
            image_id: props.user.image_id,
            address: props.user.address,
            validation: this.validator.valid()
        };

        this.updateProfile = this.updateProfile.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        this.setState({[event.target.name]: event.target.value});
    }

    updateProfile() {
        const { handleSubmit } = this.props;
        const validation = this.validator.validate(this.state);

        this.setState({
            validation: validation
        });

        if (validation.isValid) {
            const payload = pick(this.state, ['firstname', 'lastname', 'email', 'phone', 'image_id', 'username', 'address']);

            handleSubmit(payload);
        }
    };

    render() {
        const { validation } = this.state;

        return (
            <Row>
                <Col sm="12">
                    <Form className="Left">
                        <FormGroup>
                            <Label for="firstname">Nama Depan</Label>
                            <Input invalid={validation.firstname.isInvalid} type="text" name="firstname" id="register_firstname" placeholder="Nama Depan" onChange={this.handleChange} value={this.state.firstname} />
                            <FormFeedback>{validation.firstname.message}</FormFeedback>
                        </FormGroup>
                        <FormGroup>
                            <Label for="lastname">Nama Belakang</Label>
                            <Input invalid={validation.lastname.isInvalid} type="text" name="lastname" id="register_lastname" placeholder="Nama Belakang" onChange={this.handleChange} value={this.state.lastname} />
                            <FormFeedback>{validation.lastname.message}</FormFeedback>
                        </FormGroup>
                        <FormGroup>
                            <Label for="email">Email</Label>
                            <Input invalid={validation.email.isInvalid} type="email" name="email" id="register_email" placeholder="example@ditenun.com" onChange={this.handleChange} value={this.state.email} />
                            <FormFeedback>{validation.email.message}</FormFeedback>
                        </FormGroup>

                        <FormGroup>
                            <Label for="phone">Phone</Label>
                            <Input type="number" name="phone" id="phone" placeholder="+628" onChange={this.handleChange} invalid={validation.phone.isInvalid} value={this.state.phone} />
                            <FormFeedback>{validation.phone.message}</FormFeedback>
                        </FormGroup>
                        <FormGroup>
                            <Label for="address">Alamat</Label>
                            <Input invalid={validation.address.isInvalid} type="textarea" name="address" id="address" value={this.state.address} onChange={this.handleChange} rows={4}/>
                            <FormFeedback>{validation.address.message}</FormFeedback>
                        </FormGroup>
                    </Form>
                    <Button onClick={this.updateProfile}>Update</Button>
                </Col>
            </Row>
        )
    }
}

RegisterForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
  };

export default RegisterForm;
