import React, { Component } from 'react';
import {
    Button,
    Row,
    Col,
    Form,
    FormGroup,
    Label,
    Input
} from 'reactstrap';
import PropTypes from 'prop-types';

class LoginForm extends Component {
    constructor() {
        super();

        this.state = {
            email: '',
            password: '',
        };

        this.login = this.login.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        this.setState({[event.target.name]: event.target.value});
    }

    login() {
        const { email, password } = this.state;
        this.props.handleLogin(email, password);
    };

    render() {
        return (
            <Row>
                <Col sm="12">
                    <h1>selamat datang !</h1>
                    <Form className="Left">
                        <FormGroup>
                            <Label for="email">your email</Label>
                            <Input type="email" name="email" id="email" placeholder="example@ditenun.com" onChange={this.handleChange} />
                        </FormGroup>
                        <FormGroup>
                            <Label for="password">password</Label>
                            <Input type="password" name="password" id="password" placeholder="password" onChange={this.handleChange} />
                        </FormGroup>
                    </Form>
                    <Button onClick={this.login}>Masuk</Button>
                </Col>
            </Row>
        )
    }
}

LoginForm.propTypes = {
    handleLogin: PropTypes.func.isRequired,
  };

export default LoginForm;
