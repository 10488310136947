/**
 * @module Sagas/User
 * @desc User
 */
import { call, put, select } from 'redux-saga/effects';

import OrderAPI from '../utils/api/order';
import { handleError } from '../actions/app';
import { getCartSummary } from '../actions/cart';
import { showSuccess } from '../actions/notification';
import { getCustomerOrder as getOrderList } from '../actions/order';
import * as types from '../constants/order';

/**
 * Cart Summary
 */
export function* getCustomerOrder() {
    try {
        let { detail } = yield select(state => state.user);

        const result = yield call(OrderAPI.all, detail.id, detail.api_token);

        yield put({
            type: types.GET_ALL_ORDER_SUCCESS,
            payload: result,
        });
    } catch (err) {
        yield put(handleError(err));

        yield put({
            type: types.GET_ALL_ORDER_FAILURE,
            payload: err,
        });
    }
}

export function* confirmOrder({ params, payload }) {
    try {
        let { detail } = yield select(state => state.user);

        const result = yield call(OrderAPI.confirm, params.order_id, payload, detail.api_token);

        yield put({
            type: types.CONFIRM_ORDER_SUCCESS,
            payload: result,
        });

        yield put(showSuccess('Order berhasil di konfirmasi'));

        yield put(getOrderList());
        yield put(getCartSummary());

    } catch (err) {
        yield put({
            type: types.CONFIRM_ORDER_FAILURE,
            payload: err,
        });
    }
}
