/**
 * @module Sagas/Generator
 * @desc User
 */
import { call, put, select } from 'redux-saga/effects';

import DitenunAPI from '../utils/api/ditenun';
import * as types from '../constants/generator';
import { handleError } from '../actions/app';

export function* generateKristik({ payload }) {
    try {
        let { detail } = yield select(state => state.user);

        const result = yield call(DitenunAPI.generateKristik, payload, detail.api_token);
       
        yield put({
            type: types.GENERATE_KRISTIK_SUCCESS,
            payload: result,
        });
    } catch (err) {
        yield put(handleError(err));

        yield put({
            type: types.GENERATE_KRISTIK_FAILURE,
            payload: err,
        });
    }
}

export function* generateMotif({ payload }) {
    try {
        let { detail } = yield select(state => state.user);
        const result = yield call(DitenunAPI.generateMotif, payload, detail.api_token);
        yield put({
            type: types.GENERATE_MOTIF_SUCCESS,
            payload: result,
        });
    } catch (err) {
        yield put(handleError(err));

        yield put({
            type: types.GENERATE_MOTIF_FAILURE,
            payload: err,
        });
    }
}

export function* getAllKristik() {
    try {
        let { detail } = yield select(state => state.user);

        const result = yield call(DitenunAPI.getAllKristik, detail.api_token);

        yield put({
            type: types.GET_ALL_KRISTIK_SUCCESS,
            payload: result,
        });
    } catch (err) {
        yield put(handleError(err));

        yield put({
            type: types.GET_ALL_KRISTIK_FAILURE,
            payload: err,
        });
    }
}

export function* getAllMotif() {
    try {
        let { detail } = yield select(state => state.user);

        const result = yield call(DitenunAPI.getAllMotif, detail.api_token);
        yield put({
            type: types.GET_ALL_MOTIF_SUCCESS,
            payload: result,
        });
    } catch (err) {
        yield put(handleError(err));
        yield put({
            type: types.GET_ALL_MOTIF_FAILURE,
            payload: err,
        });
    }
}