import React from 'react';
import { connect } from 'react-redux';
import { Badge, Navbar, NavbarBrand, NavbarToggler, Nav, NavItem, NavLink, Collapse } from 'reactstrap';
import LoginModal from "../auth/Login";
import ShoppingBasket from '@material-ui/icons/ShoppingBasket';

import { getCartSummary } from '../../actions/cart';

class Header extends React.Component{
    constructor(props) {
        super(props);

        this.state = {
            isOpen: false
        };

        this.toggle = this.toggle.bind(this);
    }

    componentDidMount() {
        const { dispatch, isLoggedIn } = this.props;

        if (isLoggedIn) {
            dispatch(getCartSummary());
        }
    }

    isActive(item){
        const { location } = window;

        const pathArr = location.pathname.split('/');
        pathArr.shift();

        return pathArr.includes(item);
    }

    toggle() {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    render() {
        return(
            <div>
                <Navbar className="Header" light expand="md">
                    <NavbarBrand href="/">
                        <img className="Header-logo" src="/logo.png" alt="Ditenun Logo"/>
                        <span className="Header-name">Ditenun</span>
                        <span></span>
                        <img src="/logo-lpdp.PNG" alt="LPDP Logo"/>
                    </NavbarBrand>
                    
                    <NavbarToggler onClick={this.toggle} />
                    <Collapse isOpen={this.state.isOpen} navbar>
                        <Nav className="ml-auto" navbar>
                            <NavItem active={this.isActive('')}>
                                <NavLink  href="/">beranda</NavLink>
                            </NavItem>
                            <NavItem active={this.isActive('product')}>
                                <NavLink  href="/product">produk</NavLink>
                            </NavItem>
                            <NavItem active={this.isActive('profile')}>
                                <NavLink  href="/profile-penenun">profile</NavLink>
                            </NavItem>
                            {/* <NavItem>
                                <NavLink href="/app">app</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink href="/help">bantuan <Badge color="info" pill>soon</Badge></NavLink>
                            </NavItem> */}
                            <NavItem active={this.isActive('account')} className="Button-login">
                                <LoginModal />
                            </NavItem>
                            <NavItem active={this.isActive('cart')}>
                                <NavLink href="/cart">
                                  <ShoppingBasket />
                                  <Badge color="dark">{this.props.total}</Badge>
                                </NavLink>
                            </NavItem>
                        </Nav>
                    </Collapse>
                </Navbar>
             </div>
        );
    }
}

const mapStateToProps = ({ user, cart }) => {
    return {
      isLoggedIn: user.isLoggedIn,
      total: cart.total,
    }
};

export default connect(mapStateToProps)(Header);
