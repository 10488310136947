/**
 * @module Sagas/Product
 * @desc Product
 */
import { call, put, take, select } from 'redux-saga/effects';

import ProductAPI from '../utils/api/product';
import * as types from '../constants/product';
import * as modalTypes from '../constants/modal';
import * as userTypes from '../constants/user';
import { handleError } from '../actions/app';
import { showSuccess } from '../actions/notification';

/**
 * Get all product
 */
export function* allProduct() {
    try {
        const result = yield call(ProductAPI.all);

        yield put({
            type: types.GET_ALL_PRODUCT_SUCCESS,
            payload: result,
        });
    } catch (err) {
        yield put({
            type: types.GET_ALL_PRODUCT_FAILURE,
            payload: err,
        });
    }
}

export function* category({ params }) {
    try {
        const result = yield call(ProductAPI.category, params.category_id);

        yield put({
            type: types.GET_PRODUCT_BY_CATEGORY_SUCCESS,
            payload: result,
        });
    } catch (err) {
        yield put({
            type: types.GET_PRODUCT_BY_CATEGORY_FAILURE,
            payload: err,
        });
    }
}

export function* detail({ params }) {
    try {
        const result = yield call(ProductAPI.detail, params.product_id);

        yield put({
            type: types.GET_PRODUCT_DETAIL_SUCCESS,
            payload: result,
        });
    } catch (err) {
        yield put({
            type: types.GET_PRODUCT_DETAIL_FAILURE,
            payload: err,
        });
    }
}

export function* images({ params }) {
    try {
        const result = yield call(ProductAPI.images, params.product_id);

        yield put({
            type: types.GET_PRODUCT_IMAGES_SUCCESS,
            payload: result,
        });
    } catch (err) {
        yield put({
            type: types.GET_PRODUCT_IMAGES_FAILURE,
            payload: err,
        });
    }
}

export function* addToCart({ payload }) {
    try {
        let { isLoggedIn, detail } = yield select(state => state.user);

        if (!isLoggedIn) {
            yield put({
                type: modalTypes.TOGGLE_MODAL,
            });

            const auth = yield take(userTypes.USER_LOGIN_SUCCESS);

            detail = auth.payload.user;
        }

        const result = yield call(ProductAPI.addToCart, detail.id, payload.product, detail.api_token);

        yield put({
            type: types.ADD_PRODUCT_TO_CART_SUCCESS,
            payload: result,
        });

        yield put(showSuccess('Produk telah di tambahkan ke cart'));
    } catch (err) {
        yield put(handleError(err));

        yield put({
            type: types.ADD_PRODUCT_TO_CART_FAILURE,
            payload: err,
        });
    }
}
