import React from 'react';
import {
    Container,
    Row, Col,
    Button,
    ListGroup,
    ListGroupItem
} from 'reactstrap';
import Carousel from 'nuka-carousel';
import { connect } from 'react-redux';
import { detail, images, addToCart } from '../../actions/product';
import currency from 'currency.js';
// import { BASE_URL } from '../../Config';


class ProductDetails extends React.Component{
    constructor(props){
        super(props);

        this.state = {
            loading: true,
            products: [],
            images:[],
            id: props.productId,
        };

        this.handleImageLoaded = this.handleImageLoaded.bind(this);
    }

    componentWillMount() {
        const { dispatch, productId } = this.props;

        dispatch(detail(productId));
        dispatch(images(productId));
    }

    // https://github.com/FormidableLabs/nuka-carousel/issues/103
    handleImageLoaded() {
        setTimeout(() => {
            window.dispatchEvent(new Event('resize'));
        }, 0);
    }

    addToCart(product) {
        const { dispatch } = this.props;

        dispatch(addToCart(product));
    }

    renderImages() {
        return this.props.images.map((image) => {
            // http://178.128.106.214:8000
            // return <img key={image.id} onLoad={this.handleImageLoaded} src={'http://api.ditenun.com'+image.url} alt={image.product_name} />
            return <img key={image.id} onLoad={this.handleImageLoaded} src={image.url} alt={image.product_name} />
        });
    }

    renderProducts() {
        return this.props.products.map((product) => {
            return(
                <Col key={product.id} xs="12" sm="6" md="6" className="Product-description">
                    <h1>{product.product_name}</h1>
                    <div className="price">
                        <strong>Rp {currency(product.price_price, { symbol: 'Rp', separator: '.', decimal: ',', precision: 0 }).format()} </strong>
                    </div>
                    <p>{product.product_description}</p>
                    <ListGroup className="list-solid text-left">
                        <ListGroupItem>
                            Warna: <span>{product.color_name}</span>
                        </ListGroupItem>
                        <ListGroupItem>
                            Ukuran: <span>{product.size_name}</span>
                        </ListGroupItem>
                        <ListGroupItem>
                            Stock: <span>{product.stock_quantity}</span>
                        </ListGroupItem>
                     </ListGroup>
                    <Button onClick={() => this.addToCart(product) }>add to cart</Button>
                </Col>
            )
        });
    }

    render() {
        return(
            <div className="Product-detail">
                <section>
                    <Container>
                        <Row className="Product-detail-content">
                            <Col xs="12" sm="6">
                                <Carousel autoplay={ false }>
                                    { this.renderImages() }
                                </Carousel>
                            </Col>
                            { this.renderProducts() }
                        </Row>
                    </Container>
                </section>
            </div>
        );
    }
}
const mapStateToProps = ({ user, product }) => {
    return {
      isLoggedIn: user.isLoggedIn,
      products: product.detail,
      images: product.images,
    }
};

export default connect(mapStateToProps)(ProductDetails);
