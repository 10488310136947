import React, { Component } from 'react';
import NotificationSystem from 'react-notification-system';
import './components/css/App.css';
import './components/css/Notification.css';
import Routes from './Router';
import Header from './components/common/Header';
import Footer from './components/common/Footer';
import { connect } from 'react-redux';

const mapStateToProps = ({ user, cart, notification }) => {
    return {
        isLoggedIn: user.isLoggedIn,
        totalCart: cart.total,
        notification: notification,
    }
};


class App extends Component {
    constructor(props) {
        super(props);

        this.notificationSystem = React.createRef();
        this.notificationStyle = false;
    }

    componentWillReceiveProps(nextProps) {
        const notification = this.notificationSystem.current;

        if (Object.keys(nextProps.notification).length > 0) {
            notification.addNotification({
                level: nextProps.notification.level,
                message: nextProps.notification.message,
                autoDismiss: 3,
                callback: function() {
                  console.log('Notification button clicked!');
                }
            });
        }
    }

    render() {
        return (
            <div className="App">
                <Header/>
                <Routes isLoggedIn={this.props.isLoggedIn} />
                <Footer/>
                <NotificationSystem ref={this.notificationSystem} style={this.notificationStyle}/>
            </div>
        );
    }
}

export default connect(mapStateToProps)(App);
