import React from 'react';
import { connect } from 'react-redux';
import {
    Container,
    Col,
    Modal,
    ModalBody,
    Row
} from 'reactstrap';
import SideMenu from '../components/account/SideMenu';
import OrderList from '../components/account/OrderList';
import ConfirmOrder from '../components/account/ConfirmOrder';

import { logout } from '../actions/user';
import { getCustomerOrder, confirmOrder } from '../actions/order';

class Order extends React.Component{
    constructor(props) {
        super(props);

        this.state = {
            modalOpened: false,
            order: {}, // order object to confirm
        };

        this.logout = this.logout.bind(this);
        this.toggleModalConfirmOrder = this.toggleModalConfirmOrder.bind(this);
        this.processConfirmOrder = this.processConfirmOrder.bind(this);
    }

    componentWillMount() {
        const { dispatch } = this.props;

        dispatch(getCustomerOrder());
    }

    toggleModalConfirmOrder(order) {
        this.setState({
            order: order,
            modalOpened: !this.state.modalOpened
        });
    }

    processConfirmOrder(orderId, payload) {
        const { dispatch } = this.props;

        dispatch(confirmOrder(orderId, payload));

        this.setState({
            order: {},
            modalOpened: !this.state.modalOpened
        });
    }

    logout() {
        const { dispatch } = this.props;

        dispatch(logout());
    }

    render() {
        return(
            <Container className="Content-wrapper">
                <div className="Ditenun-list-cart">
                    <h1>Daftar Pesanan</h1>
                    <Row>
                        <Col xs="12" sm="4">
                            <SideMenu user={this.props.user} handleLogout={this.logout}/>
                        </Col>
                        <Col xs="12" sm="8">
                            <OrderList items={this.props.orders} handleConfirm={this.toggleModalConfirmOrder}/>
                        </Col>
                    </Row>
                </div>
                <Modal isOpen={this.state.modalOpened} toggle={this.toggleModalConfirmOrder} size="md" className="modal-address">
                    <ModalBody className="Ditenun Ditenun-modal">
                        <ConfirmOrder
                            order={this.state.order}
                            handleSubmit={this.processConfirmOrder}
                        />
                    </ModalBody>
                </Modal>
            </Container>
        );
    }
}

const mapStateToProps = ({ user, order }) => {
    return {
      isLoggedIn: user.isLoggedIn,
      user: user.detail,
      orders: order.list,
    }
};

export default connect(mapStateToProps)(Order);
