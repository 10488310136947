import React, { Component } from 'react';
import { Alert, TabPane, Modal, ModalBody, TabContent, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import '../css/Login.css';

import { connect } from 'react-redux';
import { login, logout, register } from '../../actions/user';
import { toggle } from '../../actions/modal';

import UserMenu from './partials/UserMenu';
import LoginForm from './partials/LoginForm';
import RegisterForm from './partials/RegisterForm';

class Login extends Component{
    constructor() {
        super();

        this.state = {
            activeTab: '1',
            redirect: false,
        };

        this.toggleModal = this.toggleModal.bind(this);
        this.toggleTab = this.toggleTab.bind(this);
        this.login = this.login.bind(this);
        this.register = this.register.bind(this);
        this.logout = this.logout.bind(this);
    }

    toggleModal() {
        this.props.dispatch(toggle());
    }

    toggleTab(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    logout() {
        this.props.dispatch(logout());

        this.setState({
            redirect: true,
        });
    }

    login(email, password) {
        if(email && password) {
            this.props.dispatch(login(email, password));
        }
    }

    register(payload) {
        this.toggleModal();

        const { dispatch } = this.props;

        dispatch(register(payload));
    }

    render() {
        if (this.props.isLoggedIn) {
            return <UserMenu userDetail={this.props.detail} handleLogout={this.logout} />
          } else {
              return(
                  <div>
                    <NavLink onClick={this.toggleModal} href="#">Masuk/Daftar{this.props.buttonLabel}</NavLink>
                    <Modal isOpen={this.props.modalOpened} toggle={this.toggleModal} className={this.props.className}>
                        <ModalBody className="Ditenun Ditenun-modal">
                            <Nav tabs>
                                <NavItem className="Reset">
                                    <NavLink
                                        className={classnames({ active: this.state.activeTab === '1' })}
                                        onClick={() => { this.toggleTab('1'); }}
                                    >login</NavLink>
                                </NavItem>
                                <NavItem className="Reset">
                                    <NavLink
                                        className={classnames({ active: this.state.activeTab === '2' })}
                                        onClick={() => { this.toggleTab('2'); }}
                                    >signup</NavLink>
                                </NavItem>
                            </Nav>

                            <TabContent activeTab={this.state.activeTab}>
                                <TabPane tabId="1">
                                    <Alert color="danger" isOpen={this.props.loginRequested && this.props.loginFailed}>
                                        Login gagal, pastikan email dan password sudah benar.
                                    </Alert>
                                    <LoginForm handleLogin={this.login} />
                                </TabPane>
                                <TabPane tabId="2">
                                    <RegisterForm handleRegister={this.register} />
                                </TabPane>
                            </TabContent>
                        </ModalBody>
                    </Modal>
                </div>
            );
         }
    }
}
// Subscribe component to redux store and merge the state into
 // component's props
 const mapStateToProps = ({ user, modal }) => {
    return {
      detail: user.detail,
      isLoggedIn: user.isLoggedIn,
      loginFailed: user.loginFailed,
      loginRequested: user.loginRequested,
      modalOpened: modal.opened,
    }
};

export default connect(mapStateToProps)(Login);
