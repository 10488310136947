/**
 * @namespace Constants
 * @desc Order constants
 */

  export const GET_ALL_ORDER = 'GET_ALL_OREDR';
  export const GET_ALL_ORDER_SUCCESS = 'GET_ALL_ORDER_SUCCESS';
  export const GET_ALL_ORDER_FAILURE = 'GET_ALL_ORDER_FAILURE';

  export const CONFIRM_ORDER = 'CONFIRM_ORDER';
  export const CONFIRM_ORDER_SUCCESS = 'CONFIRM_ORDER_SUCCESS';
  export const CONFIRM_ORDER_FAILURE = 'CONFIRM_ORDER_FAILURE';
