import React from 'react';
import {
    Button,
    NavLink
} from 'reactstrap';
import PropTypes from 'prop-types';

const CheckoutAdress = (props) => {
    return (
        <div className="box box-solid">
            <div className="box-header with-border text-left">
                <h3 className="box-title">Alamat Pengiriman</h3>
            </div>

            <div className="box-body text-left">
                { props.address === null
                    ? <NavLink href="#">
                        <Button color="checkout" onClick={() => props.addAddress() }>Gunakan Alamat Baru</Button>
                    </NavLink>
                    : <span>{props.address.description}</span>
                }

            </div>
        </div>
    )
};

CheckoutAdress.propTypes = {
    address: PropTypes.object,
    addAddress: PropTypes.func.isRequired
  };
  
export default CheckoutAdress;
