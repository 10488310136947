import React, { Component } from 'react';
import {
    Button,
    Row,
    Col,
    Form,
    FormGroup,
    FormText,
    Label,
    Input
} from 'reactstrap';
import PropTypes from 'prop-types';
  
class ImportMotif extends Component {
    constructor(props) {
        super(props);

        this.state = {
            files: [],
            images: [],
            notAcceptedFileType: [],
        };

        this.handleChange = this.handleChange.bind(this);
        this.uploadMotif = this.uploadMotif.bind(this);
    }

    hasExtension(fileName) {
        const pattern = '(' + this.props.imgExtension.join('|').replace(/\./g, '\\.') + ')$';

        return new RegExp(pattern, 'i').test(fileName);
    }

    /*
     Read a file and return a promise that when resolved gives the file itself and the data URL
    */
    readFile(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            // Read the image via FileReader API and save image result in state.
            reader.onload = function (e) {
                // Add the file name to the data URL
                let dataURL = e.target.result;
                dataURL = dataURL.replace(";base64", `;name=${file.name};base64`);
                resolve({file, dataURL});
            };

            reader.readAsDataURL(file);
        });
    }

    handleChange(event) {
        const files = event.target.files;
        const allFilePromises = [];

        // Iterate over all uploaded files
        for (let i = 0; i < files.length; i++) {
            let file = files[i];

            // Check for file extension
            if (!this.hasExtension(file.name)) {
                const notAcceptedFileType = this.state.notAcceptedFileType.slice();
                notAcceptedFileType.push(file.name);

                this.setState({ notAcceptedFileType });

                continue;
            }

            // Check for file size
            if(file.size > this.props.maxFileSize) {
                const notAcceptedFileSize = this.state.notAcceptedFileSize.slice();
                notAcceptedFileSize.push(file.name);

                this.setState({ notAcceptedFileSize });

                continue;
            }

            allFilePromises.push(this.readFile(file));
        }

        Promise.all(allFilePromises).then(filesImported => {
            const images = this.state.images.slice();
            const files = this.state.files.slice();

            filesImported.forEach(fileImported => {
                images.push(fileImported.dataURL);
                files.push(fileImported.file);
            });

            this.setState({ images, files });
        });
    }

    uploadMotif() {
        const { handleUpload } = this.props;

        handleUpload(this.state.files, this.state.images)
    };

    render() {
        // const { validation } = this.state;

        return (
            <Row>
                <Col sm="12">
                    <Form className="Left">
                        <FormGroup>
                            <Label for="motifFile">File</Label>
                            <Input type="file" name="file" id="motifFile"
                                onChange={this.handleChange}
                                accept={this.props.accept}
                                multiple={this.props.multiple}
                            />
                            {/* <img src={this.state.file}/> */}
                            <FormText color="muted">
                                Pastikan photo yang di unggah memiliki resolusi yang cukup bagus untuk menghasilkan
                                motif/kristik yang lebih baik
                            </FormText>
                        </FormGroup>
                    </Form>
                    <Button onClick={this.uploadMotif}>Generate</Button>
                </Col>
            </Row>
            
        )
    }
}

ImportMotif.defaultProps = {
    accept: "image/*",
    imgExtension: ['.jpg', '.jpeg', '.gif', '.png'],
    maxFileSize: 2097152, // 2MB
    multiple: false,
  };
  
  ImportMotif.propTypes = {
      handleUpload: PropTypes.func.isRequired,
      multiple: PropTypes.bool,
  };

  export default ImportMotif;
