/**
 * @namespace Constants
 * @desc Cart constants
 */

export const GET_CART_LIST = 'GET_CART_LIST';
export const GET_CART_LIST_SUCCESS = 'GET_CART_LIST_SUCCESS';
export const GET_CART_LIST_FAILURE = 'GET_CART_LIST_FAILURE';

export const GET_CART_SUMMARY = 'GET_CART_SUMMARY';
export const GET_CART_SUMMARY_SUCCESS = 'GET_CART_SUMMARY_SUCCESS';
export const GET_CART_SUMMARYT_FAILURE = 'GET_CART_SUMMARYT_FAILURE';

export const INCREASE_CART_QUANTITY = 'INCREASE_CART_QUANTITY';
export const INCREASE_CART_QUANTITY_SUCCESS = 'INCREASE_CART_QUANTITY_SUCCESS';
export const INCREASE_CART_QUANTITY_FAILURE = 'INCREASE_CART_QUANTITY_FAILURE';

export const DECREASE_CART_QUANTITY = 'DECREASE_CART_QUANTITY';
export const DECREASE_CART_QUANTITY_SUCCESS = 'DECREASE_CART_QUANTITY_SUCCESS';
export const DECREASE_CART_QUANTITY_FAILURE = 'DECREASE_CART_QUANTITY_FAILURE';

export const REMOVE_PRODUCT_FROM_CART = 'REMOVE_PRODUCT_FROM_CART';
export const REMOVE_PRODUCT_FROM_CART_SUCCESS = 'REMOVE_PRODUCT_FROM_CART_SUCCESS';
export const REMOVE_PRODUCT_FROM_CART_FAILURE = 'REMOVE_PRODUCT_FROM_CART_FAILURE';

export const CHECKOUT_CART = 'CHECKOUT_CART';
export const CHECKOUT_CART_SUCCESS = 'CHECKOUT_CART_SUCCESS';
export const CHECKOUT_CART_FAILURE = 'CHECKOUT_CART_FAILURE';
