import * as types from '../constants/product';

const initialState = {
    loading: false,
    products: [],
    detail: [],
    images: [],
}

// Handles user related actions
export default function (state = initialState, action) {
    switch (action.type) {
        case types.GET_ALL_PRODUCT:
        case types.GET_PRODUCT_BY_CATEGORY:
            return {
                ...state,
                loading: true,
            }
        case types.GET_ALL_PRODUCT_SUCCESS:
            return {
                ...state,
                products: action.payload.message.map((item) =>  item.product),
                loading: false
            };

        case types.GET_PRODUCT_BY_CATEGORY_SUCCESS:
            return {
                ...state,
                products: action.payload.message.product,
                loading: false
            };

        case types.GET_PRODUCT_DETAIL_SUCCESS:
            return {
                ...state,
                detail: action.payload.message
            };

        case types.GET_PRODUCT_IMAGES_SUCCESS:
            return {
                ...state,
                images: action.payload.message
            };

        default:
            return state;
    }
}
