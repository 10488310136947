import request from './request';

export default {
    cartList(customerId, token) {
        const path = `cart/get_customer_cart/${customerId}`;
        const method = 'get';

        return request.process(method, path, null, token);
    },

    cartSummary(customerId, token) {
        const path = `cart/get_summary_cart/${customerId}`;
        const method = 'get';

        return request.process(method, path, null, token);
    },

    processCheckout(customerId, total, promoId, token) {
        const path = `transaksi/add_transaction`;
        const method = 'post';

        const payload = {
            customer_id: customerId,
            promo_id: promoId,
            payment_status_id: 2, // checkout
            payment_id: 1, // transfer to BCA
            total_price: total,
        }

        return request.process(method, path, payload, token);
    },

    updateCartDetail(cartDetailId, data, token) {
        const path = `detail_cart/${cartDetailId}`;
        const method = 'put';

        const payload = {
            cart_id: data.cart_id,
            product_id: data.product_id,
            quantity: data.quantity,
        };

        return request.process(method, path, payload, token);
    },

    removeProductFromCart(cartDetailId, token) {
        const path = `detail_cart/delete/${cartDetailId}`;
        const method = 'delete';

        return request.process(method, path, null, token);
    }
};
