import React from 'react';
import { Link } from 'react-router-dom';

class NotFound extends React.Component{
    render(){
        return(
            <div className="Ditenun-not-found Ditenun">
                <h1>Opps! page not found</h1>
                <Link to="/">Go to homepage</Link>
            </div>
        );
    }
}

export default NotFound;
