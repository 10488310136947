import React from 'react';
import { Container, Col } from 'reactstrap';

class Motif extends React.Component{
    render(){
        return(
            <Container className="Center">
                <Col className="Left">
                   <h1>DITENUN</h1>
                   <kbd>About</kbd>
                   <p>Misi kami adalah mengembangkan motif tenun Nusantara dan membuatnya dapat dengan mudah diakses secara universal</p>
                    <kbd>Produk</kbd>
                    <p>Penelitian yang mendalam dan pengawasan yang berkesinambungan untuk menghasilkan kualitas produk maksimal</p>
                    <kbd>Komitmen kami</kbd>
                    <p>Meningkatkan kesejahteraan masyarakat perajin kain tenun melalui keterampilan tradisional berdasarkan teknologi. </p>


                    <kbd>Cerita kami</kbd>
                    <p>
                    Tenun adalah unsur budaya yang berasal dari keterampilan yang diturunkan dari generasi ke generasi. 
                    Begitu banyak potensi ekonomi yang bisa dikembangkan dengan tenun. 
                    Perajin tenun yang selama ini hanya mengerjakan tenun yang penggunaannya terbatas pada kesempatan seremonial adat, 
                    diharapkan dapat mengembangkan desain motif yang lebih modern dan popular sehingga dapat mengembangkan pemasaran dan meluaskan pemakaian tenun kepada orang awam.
                    <br />
                    <br />
                    DiTenun merupakan kolaborasi multidisiplin antara kesenian dan keterampilan tradisional menenun yang digabungkan dengan kecanggihan teknologi yang dapat 
                    membantu memudahkan proses desain dan mengembangkan desain tenun. Dalam penelitian ini, berbagai bidang ilmu yaitu matematika-geometri, sains computer 
                    (Machine learning dan pattern recognition) dan seni (baik berupa audio maupun visual) dikaji mendalam guna menghasilkan piranti cerdas berbasis mobile 
                    yang dapat membantu perajin tenun tradisonal dalam menghasilkan motif tenun yang baru. 
                    DiTenun dikembangkan oleh Institut Teknologi Del bekerjasama dengan CV. Piksel Indonesia sejak tahun 2016 yang berbasis di Sitoluama, IT Del.
                    <br />
                    <br />
                    Aplikasi DiTenun membuat perancangan dan pembuatan motif tenun baru dengan mudah, cepat dan otomatis.
                    Selain aplikasi berbagai produk turunan dihasilkan dan dikembangkan seperti aplikasi DiTenun berbasis Web dan produk barang seperti buku cetak motif dan motif digital berkualitas profesional,
                    produk fashion, produk home decore, dan produk lainnya.
                    <br />
                    <br />
                    DiTenun mampu mengolah dan mengembangkan serta mewariskan nilai budaya tenun sebagai salah satu nilai budaya luhur Indonesia kepada generasi muda dan menjadi bukti 
                    perjalanan sejarah kreativitas desain tenun Nusantara. Penelitian ini juga diharapkan dapat mengembangkan industri tenun tradisional Indonesia yang akhirnya 
                    mampu meningkatkan kesejahteraan hidup para perajin tenun tradisional Indonesia.
                   </p>
                </Col>
                
            </Container>
        );
    }     
}
export default Motif;