/**
 * @module Actions/Modal
 * @desc Modal Actions
 */

import * as types from '../constants/modal';

export const toggle = () => {
    return {
      type: types.TOGGLE_MODAL,
    }
};
