import React from 'react';
import {
  Container, Col, Form,
  FormGroup, Label, Input,
  Button, FormFeedback,
} from 'reactstrap';
import '../css/Register.css';
import { API_URL } from '../../Config';
import axios from 'axios';
import  { Redirect } from 'react-router-dom';
// import { browserHistory } from 'react-router';


class FormRegister extends React.Component {

            constructor(props) {
                super(props);
                this.state = {

                        'email': '',
                        'password': '',
                        'username' : "",
                        'firstname' : "",
                        'lastname' : "",
                        'name' : "",
                        'address' : "",
                        'phone' : "",

                    validate: {
                        emailState: '',
                        confirmState: '',
                        formState: '',
                        usernameState: '',
                        addressState : '',
                        passwordState : '',
                    },

                    redirect : false

                }
                this.handleChange = this.handleChange.bind(this);
                this.submitForm = this.submitForm.bind(this);
            }

            validateEmail(e) {
                const emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                const { validate } = this.state
                    if (emailRex.test(e.target.value)) {
                        validate.emailState = 'has-success'
                    } else {
                        validate.emailState = 'has-danger'
                    }
                    this.setState({ validate })
            }

            validatePassword(e) {
                // const passwordRex = '';
                const { validate } = this.state
                    // if (passwordRex.test(e.target.value)) {
                    //     validate.passwordState = 'has-success'
                    // } else {
                    //     validate.passwordState = 'has-danger'
                    // }
                    this.setState({ validate })
            }

            validateForm(e) {
                    const formRex = /^(?=.{1,40}$)[a-zA-Z]+(?:[-'\s][a-zA-Z]+)*$/;
                    const { validate } = this.state
                    if (formRex.test(e.target.value)) {
                        validate.formState = 'has-success'
                    } else {
                        validate.formState = 'has-danger'
                    }
                        this.setState({ validate })
             }

           validateUsername(e) {
                        const usernameRex = /^[A-Za-z0-9]+(?:[ _-][A-Za-z0-9]+)*$/;
                        const { validate } = this.state
                        if (usernameRex.test(e.target.value)) {
                            validate.usernameState = 'has-success'
                        } else {
                            validate.usernameState = 'has-danger'
                        }
                            this.setState({ validate })
             }

            validateAddress(e) {
                const addressRex = /^[a-zA-Z0-9\s,'-]*$/;
                const { validate } = this.state
                if (addressRex.test(e.target.value)) {
                    validate.addressState = 'has-success'
                } else {
                    validate.addressState = 'has-danger'
                }
                    this.setState({ validate })
            }

            validatePhone(e) {
                const phoneRex = /\+?([ -]?\d+)+|\(\d+\)([ -]\d+)/;
                const { validate } = this.state
                if (phoneRex.test(e.target.value)) {
                    validate.addressState = 'has-success'
                } else {
                    validate.addressState = 'has-danger'
                }
                    this.setState({ validate })
            }

            handleChange = async (event) => {
                const { target } = event;
                const value = target.type === 'checkbox' ? target.checked : target.value;
                const { name } = target;
                await this.setState({
                    [ name ]: value,
                });
            }

            //comment dulu, belum bisa\\

            // validateConfirmPassword(e) {
            //     const password = this.state.password;
            //     console.log(password);
            //     const confirm_password = this.state.confirm_password;
            //     const { validate } = this.state
            //     if (password === confirm_password) {
            //         validate.confirmState = 'has-success'
            //     } else {
            //         validate.confirmState = 'has-danger'
            //     }
            //     this.setState({ validate })
            // }

            submitForm(e) {
                e.preventDefault();
                console.log(`Email: ${ this.state.email + ": " + this.state.password + ": " + this.state.username + ": " + this.state.firstname + ": " + this.state.lastname + ": " + this.state.name + ": " + this.state.address + ": " + this.state.phone}`)


                    var self = this;
                    var data={
                        "email": this.state.email,
                        "password": this.state.password,
                        "username": this.state.username,
                        "firstname": this.state.firstname,
                        "lastname": this.state.lastname,
                        "name": this.state.name,
                        "address": this.state.address,
                        "phone": this.state.phone
                    }
                    // var headers = {
                    //     'Content-Type': 'application/json',
                    // }

                    axios.post(API_URL + '/auth/signup', data)
                    .then(function(response) {
                    // console.log(response['data']['token']);
                    // console.log(response.status);
                        if(response.status === 200){
                                console.log("Register successfull");
                                // var uploadScreen=[];
                                    // uploadScreen.push(<UploadScreen appContext={self.props.appContext}/>)
                                    // self.props.appContext.setState({loginPage:[],uploadScreen:uploadScreen})
                                    self.setState({ redirect: true })
                        }
                        else if(response.status === 204){
                            console.log("Register failed");
                            alert("Register failed")
                        }
                        else{
                            console.log("Register failed");
                            alert("Register failed");
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                    });

            }

    render() {
        const { email, password, username, firstname, lastname, name, address, phone, redirect } = this.state;
            if (redirect) {
                return (<Redirect to={'/login'}/>)
            }

                return (
                        <Container className="Register">
                        <h2>Registration Form</h2>
                        <Form className="form" onSubmit={ (e) => this.submitForm(e) }>
                        <Col>
                            <FormGroup>
                            <Label>Email</Label>
                            <Input
                                type="email"
                                name="email"
                                id="Email"
                                placeholder="myemail@gmail.com"
                                value={ email }
                                valid={ this.state.validate.emailState === 'has-success' }
                                invalid={ this.state.validate.emailState === 'has-danger' }
                                onChange={ (e) => {
                                            this.validateEmail(e)
                                            this.handleChange(e)
                                        } }
                            />
                            <FormFeedback valid>
                                            {/* Test berhasil */}
                            </FormFeedback>
                            <FormFeedback>
                                            Email salah
                            </FormFeedback>
                            {/* <FormText></FormText> */}
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                            <Label for="examplePassword">Password</Label>
                            <Input
                                type="password"
                                name="password"
                                id="Password"
                                placeholder="********"
                                value={ password }
                                valid={ this.state.validate.emailState === 'has-success' }
                                invalid={ this.state.validate.emailState === 'has-danger' }
                                onChange={ (e) => {
                                            this.validatePassword(e)
                                            this.handleChange(e)
                                        } }
                            />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                            <Label>Username</Label>
                            <Input
                                type="text"
                                name="username"
                                id="username"
                                // placeholder="myemail@email.com"
                                value={ username }
                                valid={ this.state.validate.usernameState === 'has-success' }
                                invalid={ this.state.validate.usernameState === 'has-danger' }
                                onChange={ (e) => {
                                            this.validateForm(e)
                                            this.handleChange(e)
                                        } }
                            />
                            <FormFeedback valid>
                                            {/* Test berhasil */}
                            </FormFeedback>
                            <FormFeedback>
                                            {/* Email salah */}
                            </FormFeedback>
                            {/* <FormText></FormText> */}
                            </FormGroup>
                        </Col>

                        <Col>
                            <FormGroup>
                            <Label>First Name</Label>
                            <Input
                                type="text"
                                name="firstname"
                                id="firstname"
                                // placeholder="myemail@email.com"
                                value={ firstname }
                                valid={ this.state.validate.formState === 'has-success' }
                                invalid={ this.state.validate.formState === 'has-danger' }
                                onChange={ (e) => {
                                            this.validateForm(e)
                                            this.handleChange(e)
                                        } }
                            />
                            <FormFeedback valid>
                                            {/* Test berhasil */}
                            </FormFeedback>
                            <FormFeedback>
                                            {/* Email salah */}
                            </FormFeedback>
                            {/* <FormText></FormText> */}
                            </FormGroup>
                        </Col>

                        <Col>
                            <FormGroup>
                            <Label>Last Name</Label>
                            <Input
                                type="text"
                                name="lastname"
                                id="lastname"
                                // placeholder="myemail@email.com"
                                value={ lastname }
                                valid={ this.state.validate.formState === 'has-success' }
                                invalid={ this.state.validate.formState === 'has-danger' }
                                onChange={ (e) => {
                                            this.validateForm(e)
                                            this.handleChange(e)
                                        } }
                            />
                            <FormFeedback valid>
                                            {/* Test berhasil */}
                            </FormFeedback>
                            <FormFeedback>
                                            {/* Email salah */}
                            </FormFeedback>
                            {/* <FormText></FormText> */}
                            </FormGroup>
                        </Col>

                        <Col>
                            <FormGroup>
                            <Label>Name</Label>
                            <Input
                                type="text"
                                name="name"
                                id="name"
                                // placeholder="myemail@email.com"
                                value={ name }
                                valid={ this.state.validate.formState === 'has-success' }
                                invalid={ this.state.validate.formState === 'has-danger' }
                                onChange={ (e) => {
                                            this.validateForm(e)
                                            this.handleChange(e)
                                        } }
                            />
                            <FormFeedback valid>
                                            {/* Test berhasil */}
                            </FormFeedback>
                            <FormFeedback>
                                            {/* Email salah */}
                            </FormFeedback>
                            {/* <FormText></FormText> */}
                            </FormGroup>
                        </Col>

                        <Col>
                            <FormGroup>
                            <Label>Address</Label>
                            <Input
                                type="text"
                                name="address"
                                id="address"
                                // placeholder="myemail@email.com"
                                value={ address }
                                valid={ this.state.validate.addressState === 'has-success' }
                                invalid={ this.state.validate.addressState === 'has-danger' }
                                onChange={ (e) => {
                                            this.validateAddress(e)
                                            this.handleChange(e)
                                        } }
                            />
                            <FormFeedback valid>
                                            {/* Test berhasil */}
                            </FormFeedback>
                            <FormFeedback>
                                            {/* Email salah */}
                            </FormFeedback>
                            {/* <FormText></FormText> */}
                            </FormGroup>
                        </Col>


                        <Col>
                            <FormGroup>
                            <Label>Phone</Label>
                            <Input
                                type="text"
                                name="phone"
                                id="phone"
                                // placeholder="myemail@email.com"
                                value={ phone }
                                valid={ this.state.validate.phoneState === 'has-success' }
                                invalid={ this.state.validate.phoneState === 'has-danger' }
                                onChange={ (e) => {
                                            this.validatePhone(e)
                                            this.handleChange(e)
                                        } }
                            />
                            <FormFeedback valid>
                                            {/* Test berhasil */}
                            </FormFeedback>
                            <FormFeedback>
                                            {/* Email salah */}
                            </FormFeedback>
                            {/* <FormText></FormText> */}
                            </FormGroup>
                        </Col>

                        <Button>REGISTER !!</Button>

                    </Form>
                </Container>
        );
        //   }
    }
  }

  export default FormRegister;
