/**
 * @namespace Constants
 * @desc Profile constants
 */

export const GET_PROFILE_PENENUN = 'GET_PROFILE_PENENUN';
export const GET_PROFILE_PENENUN_SUCCESS = 'GET_PROFILE_PENENUN_SUCCESS';
export const GET_PROFILE_PENENUN_FAILURE = 'GET_PROFILE_PENENUN_FAILURE';


export const GET_ALL_PROFILE_PENENUN = 'GET_ALL_PROFILE_PENENUN';
export const GET_ALL_PROFILE_PENENUN_SUCCESS = 'GET_ALL_PROFILE_PENENUN_SUCCESS';
export const GET_ALL_PROFILE_PENENUN_FAILURE = 'GET_ALL_PROFILE_PENENUN_FAILURE';