import React from 'react';
import { Button, ButtonToolbar, ButtonGroup, Container, Col } from 'reactstrap';
import '../css/Product.css';
import PropTypes from 'prop-types';
import Pdf from '../../Katalog Ditenun Final-24Apr.pdf';
 
class Category extends React.Component{
    constructor(){
        super();

        this.productHandle = this.productHandle.bind(this);
    }

    productHandle(e){
        const category = e.target.value;

        this.props.filterByCategory(category);
    }

    onResumeClick() {
        window.open(Pdf);
      }

      functionThatOpensWindow(){
          window.open(Pdf);
      }

    render(){
        return(
            <section className="Category-menu">
                <Container>
                        <Col className="Category-row-menu">
                            <ButtonToolbar>
                                <ButtonGroup>
                                    <Button onClick={this.productHandle} value="1"> Pattern</Button>
                                    <Button onClick={this.productHandle} value="2"> Souvenir</Button>
                                    <Button onClick={this.productHandle} value="3"> Fashion </Button>
                                    <Button onClick={this.productHandle} value="4">Home Decor</Button>
                                    <a href = "/Catalog - DiTenun 2020.pdf" target = "_blank">
                                        <button> Catalog </button>
                                    </a>
                                </ButtonGroup>
                            </ButtonToolbar>
                        </Col>
                    <hr />
                </Container>
            </section>
        );
    }
}

Category.propTypes = {
    filterByCategory: PropTypes.func
  };
 
export default Category;
