import { combineReducers } from 'redux';
import user from './user';
import modal from './modal';
import product from './product';
import cart from './cart';
import address from './address';
import order from './order';
import notification from './notification';
import generator from './generator';
import profile from './profile';

// Combines all reducers to a single reducer function
const rootReducer = combineReducers({
  user,
  modal,
  product,
  cart,
  address,
  order,
  notification,
  generator,
  profile
});

export default rootReducer;
