import React, { Component } from 'react';
import {
    Button,
    ButtonGroup,
    Row,
    Col,
    Table,
    Media
} from 'reactstrap';
import PropTypes from 'prop-types';
import currency from 'currency.js';

class CartList extends Component {
    renderList() {
        return this.props.items.map((item) => (
            <tr key={item.id} className="box box-solid">
                <td>
                    <Media>
                        <Media left href="#">
                        {/* http://178.128.106.214:8000 */}
                            {/* <Media object src={'http://api.ditenun.com'+item.product_image} alt={item.name} /> */}
                            <Media object src={item.product_image} alt={item.name} />
                        </Media>
                        <Media body>
                            <Row>
                                <Col md="8" className="description text-left">
                                    <Media heading>
                                      {item.name}
                                    </Media>

                                    <span className="cart-price">
                                        Rp {currency(item.price, { symbol: 'Rp', separator: '.', decimal: ',', precision: 0}).format()}
                                    </span>
                                </Col>
                                <Col md="4" className="action">
                                    <ButtonGroup>
                                        <Button close onClick={() => this.props.handleDecrease(item.id)} className="btn-circle" aria-label="decrease">
                                            <span aria-hidden>&minus;</span>
                                        </Button>
                                        <span className="quantity">{item.quantity}</span>
                                        <Button close onClick={() => this.props.handleIncrease(item.id)} className="btn-circle" aria-label="increase">
                                            <span aria-hidden>&#43;</span>
                                        </Button>
                                    </ButtonGroup>

                                    <div className="cart-remove">
                                        <Button close onClick={() => this.props.handleRemove(item.id)} aria-label="increase">
                                            <span aria-hidden>&times;</span>
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                        </Media>
                    </Media>
                </td>
            </tr>
        ));
    }

    render() {
        return (
            <Row>
                <Col sm="12">
                    <Table>
                        <tbody>
                            {this.renderList()}
                        </tbody>
                  </Table>
                </Col>
            </Row>
        )
    }
}

CartList.propTypes = {
    items: PropTypes.array.isRequired,
    handleIncrease: PropTypes.func.isRequired,
    handleDecrease: PropTypes.func.isRequired,
    handleRemove: PropTypes.func.isRequired,
  };

export default CartList;
