import React, { Component } from 'react';
import {
    Button,
    Row,
    Col,
    Form,
    FormGroup,
    FormText,
    FormFeedback,
    Label,
    Input
} from 'reactstrap';
import PropTypes from 'prop-types';
import validator from 'validator';
import pick from 'lodash.pick';
import FormValidator from '../../utils/FormValidator';

const addressValidation = new FormValidator([
    {
      field: 'label',
      method: validator.isEmpty,
      validWhen: false,
      message: 'Silahkan isi Label Alamat'
    },
    {
      field: 'district',
      method: validator.isEmpty,
      validWhen: false,
      message: 'Silahkan isi data Kota/Kecamatan'
    },
    {
      field: 'zip',
      method: validator.isEmpty,
      validWhen: false,
      message: 'Silahkan isi data Kode Pos'
    },
    {
      field: 'zip',
      method: validator.isEmpty,
      validWhen: false,
      message: 'Silahkan isi data Kode Pos'
    },
    {
      field: 'alamat',
      method: validator.isEmpty,
      validWhen: false,
      message: 'Silahkan isi detail Alamat'
    },
  ]);  

class AddressForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            label: '',
            nama: `${props.user.firstname} ${props.user.lastname}`,
            phone: props.user.phone,
            district: '',
            zip: '',
            alamat: '',
            validation: addressValidation.valid()
        };

        this.registerAddress = this.registerAddress.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        this.setState({[event.target.name]: event.target.value});
    }

    registerAddress() {
        const validation = addressValidation.validate(this.state);

        this.setState({
            validation: validation
        });

        if (validation.isValid) {
            const payload = pick(this.state, ['label','nama', 'phone','district', 'zip', 'alamat']);

            this.props.handleSubmit(payload);
        }
    };

    render() {
        const { validation } = this.state;

        return (
            <Row>
                <Col sm="12">
                    <h1>Tambah Alamat Baru</h1>
                    <Form className="Left">
                        <FormGroup>
                            <Label for="label">Label</Label>
                            <Input invalid={validation.label.isInvalid} type="text" name="label" id="label" value={this.state.label} onChange={this.handleChange} />
                            <FormText color="muted">
                                Contoh: Alamat Kantor, Alamat Rumah, Apartemen
                            </FormText>
                            <FormFeedback>{validation.label.message}</FormFeedback>
                        </FormGroup>
                        <Row form>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="nama">Nama Penerima</Label>
                                    <Input type="text" name="nama" id="nama" value={this.state.nama} onChange={this.handleChange} />
                                </FormGroup>
                          </Col>
                          <Col md={6}>
                                <FormGroup>
                                    <Label for="phone">Nomor HP</Label>
                                    <Input type="text" name="phone" id="phone" value={this.state.phone} onChange={this.handleChange} />
                                </FormGroup>
                          </Col>
                        </Row>
                        <Row form>
                            <Col md={8}>
                                <FormGroup>
                                    <Label for="district">Kota/Kecamatan</Label>
                                    <Input invalid={validation.district.isInvalid} type="text" name="district" id="district" value={this.state.district} onChange={this.handleChange} />
                                    <FormFeedback>{validation.district.message}</FormFeedback>
                                </FormGroup>
                            </Col>
                            <Col md={4}>
                                <FormGroup>
                                    <Label for="zip">Kode Pos</Label>
                                    <Input invalid={validation.zip.isInvalid} type="text" name="zip" id="zip" value={this.state.zip} onChange={this.handleChange} />
                                    <FormFeedback>{validation.zip.message}</FormFeedback>
                                </FormGroup>
                            </Col>
                        </Row>
                        <FormGroup>
                            <Label for="alamat">Alamat</Label>
                            <Input invalid={validation.alamat.isInvalid} type="textarea" name="alamat" id="alamat" rows="5" value={this.state.alamat} onChange={this.handleChange} />
                            <FormFeedback>{validation.alamat.message}</FormFeedback>
                        </FormGroup>
                    </Form>
                    <Button onClick={this.registerAddress}>Submit</Button>
                </Col>
            </Row>
        )
    }
}

AddressForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
  };
  
export default AddressForm;
