/**
 * @module Actions/Cart
 * @desc Cart Actions
 */

import * as types from '../constants/cart';

export const getCartSummary = () => {
    return {
      type: types.GET_CART_SUMMARY,
    }
};

export const increaseQuantity = (productId, quantity=1) => {
    return {
      type: types.INCREASE_CART_QUANTITY,
      payload: {
          product_id: productId,
          quantity,
      },
    }
};

export const decreaseQuantity = (productId, quantity=1) => {
    return {
      type: types.DECREASE_CART_QUANTITY,
      payload: {
          product_id: productId,
          quantity,
      },
    }
};

export const remove = (productId) => {
    return {
      type: types.REMOVE_PRODUCT_FROM_CART,
      payload: {
          product_id: productId,
      },
    }
};

export const processCheckout = (total, promoId=null) => {
    return {
      type: types.CHECKOUT_CART,
      payload: {
          total: total,
          promo_id: promoId,
      }
    }
};
