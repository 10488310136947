import React, { Component } from 'react';
import {
    Row,
    Col,
    ListGroup,
    ListGroupItem,
    Table,
    Media
} from 'reactstrap';
import PropTypes from 'prop-types';
import currency from 'currency.js';

class CheckoutItems extends Component {
    renderList() {
        return this.props.items.map((item) => (
            <tr key={item.id}>
                <td>
                    <Media>
                        <Media left href="#">
                        {/* http://178.128.106.214:8000 */}
                            {/* <Media object src={'http://api.ditenun.com'+item.product_image} alt={item.name} /> */}
                            <Media object src={item.product_image} alt={item.name} />
                        </Media>
                        <Media body className="description text-left">
                            <Media heading>
                              {item.name}
                            </Media>

                            <ListGroup>
                                <ListGroupItem>
                                    <span className="cart-price">
                                        Rp {currency(item.price * item.quantity, { symbol: 'Rp', separator: '.', decimal: ',', precision: 0}).format()}
                                    </span>
                                </ListGroupItem>
                                <ListGroupItem>
                                    <span className="cart-desc">
                                    Jumlah: { item.quantity }
                                    </span>
                                </ListGroupItem>
                             </ListGroup>
                        </Media>
                    </Media>
                </td>
            </tr>
        ));
    }

    render() {
        return (
            <Row>
                <Col sm="12" >
                    <div className="box box-solid">
                        <div className="box-body">
                            <Table>
                                <tbody>
                                    {this.renderList()}
                                </tbody>
                            </Table>
                        </div>
                        <div className="box-footer">
                            <div className="subtotal">
                                <span className="pull-left">Subtotal</span>
                                <span className="pull-right">Rp. {currency(this.props.total, { symbol: 'Rp', separator: '.', decimal: ',', precision: 0}).format()}</span>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        )
    }
}

CheckoutItems.propTypes = {
    items: PropTypes.array,
    total: PropTypes.number,
  };  

export default CheckoutItems;
