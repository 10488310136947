import React from 'react';
import { Container, Row, Col } from 'reactstrap';

class Footer extends React.Component{
    render() {
        return(
            <section className="Footer">
                <Container>
                    <Row>
                        <Col sm="6" md="6">
                        <p className="Footer-title">DiTenun</p>
                        <p className="Footer-description">
                            Piranti Cerdas Penghasil Motif Tenun Nusantara<br />
                            Institut Teknologi Del<br />
                            Jl Sisingamangaraja No 1, Sitoluama, Laguboti, Sumatera Utara<br />
                            POS: 22381
                        </p>
                        <p className="Mobile-hidden">@2018 DiTenun. All Rights Reserved</p>
                        </Col>
                        <Col sm="6" md="6">
                            <p className="Footer-sub-title">download diTenun app</p>
                            <p>
                                <a className="Footer-google" href="www.google.com"><img className="Jumbotron-image" src="https://play.google.com/intl/en_us/badges/images/badge_new.png" alt="okosad"/></a>
                            </p>
                            <p className="Footer-sub-title">Temukan kami</p>
                            <p>
                                <a className="Footer-instagram" href="https://www.instagram.com/ditenun.id/?hl=id" target="blank"><img className="Jumbotron-image" src="https://cdnjs.cloudflare.com/ajax/libs/webicons/2.0.0/webicons/webicon-instagram.png" alt="okosad"/></a>
                                <a className="Footer-facebook" href="https://www.facebook.com/Institut.Teknologi.Del" target="blank"><img className="Jumbotron-image" src="https://cdnjs.cloudflare.com/ajax/libs/webicons/2.0.0/webicons/webicon-facebook.png" alt="okosad"/></a>
                                <a className="Footer-youtube" href="https://www.youtube.com/watch?v=h6aKOLDORyk&list=UUDaTN-vICrcMaFjqi07_L9A" target="blank"><img className="Jumbotron-image" src="https://cdnjs.cloudflare.com/ajax/libs/webicons/2.0.0/webicons/webicon-youtube.png" alt="okosad" /></a>
                            </p>
                        </Col>
                    </Row>
                </Container>
            </section>
        );
    }
}

export default Footer;