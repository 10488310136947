import * as types from '../constants/user';
import * as modalTypes from '../constants/modal';

const initialState = {
    detail: {},
    isLoggedIn: false,
    token: null,
    loginFailed: false,
    loginRequested: false,
}

// Handles image related actions
export default function (state = initialState, action) {
    switch (action.type) {
        case modalTypes.TOGGLE_MODAL:
            return {
                ...state,
                loginRequested: false,
            };

        case types.USER_LOGIN:
            return {
                ...state,
                isRegistered: true,
            };

        case types.USER_REGISTER_SUCCESS:
            return {
                ...state,
                isLoggedIn: true,
                token: action.payload.token,
                detail: action.payload.user
            };

        case types.USER_LOGIN_SUCCESS:
            const { payload } = action;

            return {
                ...state,
                detail: payload.user,
                token: payload.token,
                isLoggedIn: true,
            };

        case types.USER_LOGIN_FAILURE:
            return {
                ...state,
                loginFailed: true,
            };

        case types.USER_LOGOUT_SUCCESS:
            return {
                ...initialState,
            };

        case types.USER_RESET_PASSWORD_SUCCESS:
            return {
                ...state,
                detail: {
                    ...state.detail,
                    api_token: action.payload.token,
                },
                token: action.payload.token,
            };

        case types.USER_UPDATE_PROFILE_SUCCESS:
            return {
                ...state,
                detail: {
                    ...state.detail,
                    ...action.payload,
                },
            };

        default:
            return state;
    }
}
