import * as types from '../constants/profile';

const initialState = {
    loading: false,
    profilePenenun:[]
}

// Handles user related actions
export default function (state = initialState, action) {
    switch (action.type) {
        case types.GET_PROFILE_PENENUN_SUCCESS:
            return {
                ...state,
                profilePenenun: action.payload.message !== null
                    ? action.payload.message
                    : null,
                loading :true,
            };

        case types.GET_PROFILE_PENENUN_FAILURE:
            return {
                ...state,
                profilePenenun: null, 
                loading :true,
            };

        case types.GET_ALL_PROFILE_PENENUN_SUCCESS:
            return {
                ...state,
                profilePenenun: action.payload.message !== null
                    ? action.payload.message
                    : null,
                loading :false,
            };

        case types.GET_ALL_PROFILE_PENENUN_FAILURE:
            return {
                ...state,
                profilePenenun: null, 
                loading :true,
            };        

        default:
            return state;
    }
}
