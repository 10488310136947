import { fork } from 'redux-saga/effects';
import {
    watchLogin,
    watchLogout,
    watchRegister,
    watchAllProduct,
    watchProductsByCategory,
    watchProductDetail,
    watchProductImages,
    watchAddToCart,
    watchCartSummary,
    watchGetAddress,
    watchAddAddress,
    watchCheckoutCart,
    watchGetAllOrder,
    watchConfirmOrder,
    watchAddCartQuantity,
    watchDecreaseCartQuantity,
    watchRemoveProductCart,
    watchResetPassword,
    watchUpdateProfile,
    watchNotification,
    watchGenerateKristik,
    watchGenerateMotif,
    watchGetAllKristik,
    watchGetAllMotif,
    watchShowProfile,
    watchAllProfile,
} from './watcher';

// Here, we register our watcher saga(s) and export as a single generator
// function (startForeman) as our root Saga.
export default function* startForman() {
    yield fork(watchLogin);
    yield fork(watchLogout);
    yield fork(watchRegister);
    yield fork(watchAllProduct);
    yield fork(watchProductsByCategory);
    yield fork(watchProductDetail);
    yield fork(watchProductImages);
    yield fork(watchAddToCart);
    yield fork(watchCartSummary);
    yield fork(watchGetAddress);
    yield fork(watchAddAddress);
    yield fork(watchCheckoutCart);
    yield fork(watchGetAllOrder);
    yield fork(watchConfirmOrder);
    yield fork(watchAddCartQuantity);
    yield fork(watchDecreaseCartQuantity);
    yield fork(watchRemoveProductCart);
    yield fork(watchResetPassword);
    yield fork(watchUpdateProfile);
    yield fork(watchNotification);
    yield fork(watchGenerateKristik);
    yield fork(watchGenerateMotif);
    yield fork(watchGetAllKristik);
    yield fork(watchGetAllMotif);
    yield fork(watchShowProfile);
    yield fork(watchAllProfile);
}
