/**
 * @module Actions/Modal
 * @desc Modal Actions
 */

import * as types from '../constants/user';

export const handleError = (err) => {
    console.log(err);
    if (err.code === 403) {
        return {
          type: types.USER_LOGOUT,
        }
    }

    return {
        type: 'CUSTOM_ERROR'
    }
};
