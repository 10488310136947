import request from './request';
import axios from 'axios';
import { API_URL } from '../../Config';
export default {
    all(customerId, token) {
        const path = `transaksi/allTransaksi`;
        const method = 'get';

        return request.process(method, path, null, token);
    },

    // confirm(transactionId, data, token) {
    //     const path = `transaksi/update_transaction/${transactionId}`;
    //     const method = 'put';

    //     const payload = {
    //         payment_status_list: 3, //dibayar
    //     }

    //     return request.process(method, path, payload, token);
    // },

    confirm(transactionId, data, token) {
        const path = `transaksi/update_transaction2/${transactionId}`;
        const formData = new FormData();

        formData.append("image", data.file);
        formData.append("nama", data.nama);
        formData.append("no_rek", data.no_rekening);
        formData.append("payment_status_list", 3);

        return axios.request({
            url: `${API_URL}/${path}`,
            method: 'POST',
            data: formData,
            timeout: 0,
            headers: {
              'Content-Type': 'multipart/form-data',
              token: token,
            }
        })
        .catch((error) => {
            if (error.response) {
                return Promise.reject({
                    message: error.response.data.error,
                    code: error.response.status
                });
            } else if (error.request) {
              // The request was made but no response was received
              // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
              // http.ClientRequest in node.js
              console.log(error.request);
              throw error;
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log('Error', error.message);
              throw error;
            }
        });
    },
};
