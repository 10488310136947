import React from 'react';
import { connect } from 'react-redux';
import {
    Container,
    Col,
    Row
} from 'reactstrap';
import SideMenu from '../components/account/SideMenu';
import ChangePassword from '../components/account/ChangePassword';
import { logout, resetPassword } from '../actions/user';

class Settings extends React.Component{
    constructor(props) {
        super(props);

        this.logout = this.logout.bind(this);
        this.resetPassword = this.resetPassword.bind(this);
    }

    logout() {
        const { dispatch } = this.props;

        dispatch(logout());
    }

    resetPassword(payload) {
        const { dispatch } = this.props;

        dispatch(resetPassword(payload));
    }

    render() {
        return(
            <Container className="Content-wrapper">
                <div className="Ditenun-list-cart">
                    <h1>Ganti Password</h1>
                    <Row>
                        <Col xs="12" sm="4">
                            <SideMenu
                                user={this.props.user}
                                handleLogout={this.logout}
                            />
                        </Col>
                        <Col xs="12" sm="8">
                            <div className="List-cart">
                                <ChangePassword
                                    user={this.props.user}  
                                    handleSubmit={this.resetPassword}
                                />
                            </div>
                        </Col>
                    </Row>

                </div>
            </Container>
        );
    }
}

const mapStateToProps = ({ user }) => {
    return {
      isLoggedIn: user.isLoggedIn,
      user: user.detail,
    }
};

export default connect(mapStateToProps)(Settings);
