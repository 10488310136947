/**
 * @module Actions/Modal
 * @desc Modal Actions
 */

import * as types from '../constants/notification';

export const showSuccess = (message) => {
    return {
      type: types.EMIT_NOTIFICATION,
      payload: {
          level: 'success',
          message: message
      }
    }
};

export const showError = (message) => {
    return {
      type: types.EMIT_NOTIFICATION,
      payload: {
          level: 'error',
          message: message
      }
    }
};
