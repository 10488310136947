/**
 * @namespace Constants
 * @desc Product constants
 */

export const GET_ALL_PRODUCT = 'GET_ALL_PRODUCT';
export const GET_ALL_PRODUCT_SUCCESS = 'GET_ALL_PRODUCT_SUCCESS';
export const GET_ALL_PRODUCT_FAILURE = 'GET_ALL_PRODUCT_FAILURE';

export const GET_PRODUCT_BY_CATEGORY = 'GET_PRODUCT_BY_CATEGORY';
export const GET_PRODUCT_BY_CATEGORY_SUCCESS = 'GET_PRODUCT_BY_CATEGORY_SUCCESS';
export const GET_PRODUCT_BY_CATEGORY_FAILURE = 'GET_PRODUCT_BY_CATEGORY_FAILURE';

export const GET_PRODUCT_DETAIL = 'GET_PRODUCT_DETAIL';
export const GET_PRODUCT_DETAIL_SUCCESS = 'GET_PRODUCT_DETAIL_SUCCESS';
export const GET_PRODUCT_DETAIL_FAILURE = 'GET_PRODUCT_DETAIL_FAILURE';

export const GET_PRODUCT_IMAGES = 'GET_PRODUCT_IMAGES';
export const GET_PRODUCT_IMAGES_SUCCESS = 'GET_PRODUCT_IMAGES_SUCCESS';
export const GET_PRODUCT_IMAGES_FAILURE = 'GET_PRODUCT_IMAGES_FAILURE';

export const ADD_PRODUCT_TO_CART = 'ADD_PRODUCT_TO_CART';
export const ADD_PRODUCT_TO_CART_SUCCESS = 'ADD_PRODUCT_TO_CART_SUCCESS';
export const ADD_PRODUCT_TO_CART_FAILURE = 'ADD_PRODUCT_TO_CART_FAILURE';
