import axios from 'axios';
import { API_URL } from '../../Config';

const internals = {};
internals.contentType = 'application/json';

internals.process = (method, path, payload, token, contentType=internals.contentType) => {
    const request = {
        url: `${API_URL}/${path}`,
        method: method,
        headers: {
            'Content-Type': contentType,
            'Accept': 'application/json',
        },
    };

    if (token) {
        request.params = {
            token: token,
        };
    }

    if (payload) {
        request.data = payload;
    }

    return axios.request(request)
        .then(res => {
            if (![200, 201].includes(res.status)) {
                throw new Error(res.status);
            }

            return res.data;
        })
        .catch((error) => {
            console.error(method, path, error);
            return Promise.reject({
                message: error.response.data.error,
                code: error.response.status
            });
        });
};

export default internals;
