/**
 * @namespace Constants
 * @desc App constants
 */

  export const USER_LOGIN = 'USER_LOGIN';
  export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
  export const USER_LOGIN_FAILURE = 'USER_LOGIN_FAILURE';

  export const USER_LOGOUT = 'USER_LOGOUT';
  export const USER_LOGOUT_SUCCESS = 'USER_LOGOUT_SUCCESS';
  export const USER_LOGOUT_FAILURE = 'USER_LOGOUT_FAILURE';

  export const USER_REGISTER = 'USER_REGISTER';
  export const USER_REGISTER_SUCCESS = 'USER_REGISTER_SUCCESS';
  export const USER_REGISTER_FAILURE = 'USER_REGISTER_FAILURE';

  export const USER_RESET_PASSWORD = 'USER_RESET_PASSWORD';
  export const USER_RESET_PASSWORD_SUCCESS = 'USER_RESET_PASSWORD_SUCCESS';
  export const USER_RESET_PASSWORD_FAILURE = 'USER_RESET_PASSWORD_FAILURE';

  export const USER_UPDATE_PROFILE = 'USER_UPDATE_PROFILE';
  export const USER_UPDATE_PROFILE_SUCCESS = 'USER_UPDATE_PROFILE_SUCCESS';
  export const USER_UPDATE_PROFILE_FAILURE = 'USER_UPDATE_PROFILE_FAILURE';
