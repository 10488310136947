import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import HomePage from './components/companyprofile/HomePage';
import NotFound from './components/notfound/NotFound';
import Product from './components/products/Product';
import Category from './components/products/Category';
import ProductDetails from './components/products/ProductDetails';
import Cart from './containers/Cart';
import Checkout from './containers/Checkout';
import Order from './containers/Order';
import Settings from './containers/Settings';
import Login from './components/auth/Login';
import Register from './components/auth/Register';
import ForgotPassword from './components/auth/ForgotPassword';
import ResetPassword from './components/auth/ResetPassword';
import UI from './components/templates/UI';
import Kristik from './containers/Kristik';
import Account from './containers/Account';
import AccountUpdate from './containers/AccountUpdate';
import Motif from './containers/Motif';
import Ditenun from './containers/Ditenun';
import Profile from './components/profile/ProfilePenenun';
import ProfilePenenun from './containers/ProfilePenenun';

const Router = ({ isLoggedIn }) => (
    <BrowserRouter>
        <Switch>
            {/* Link template for UI */}
            <Route exact path="/ui" component={ UI } />

            <Route exact path="/" component={ HomePage } />
            <Route exact path="/product" component={ Product } />
            <Route exact path="/product/:id" render={
                (props) => <ProductDetails
                    productId={props.match.params.id}
                />}
            />
            <Route exact path="/profile-penenun" component={ ProfilePenenun } />
            <Route exact path="/profile-penenun/:id" render={
                (props) => <Profile
                    profileId={props.match.params.id}
                />}
            />
            <Route exact path="/category" component={ Category } />
            <Route exact path="/category/:id" component={ Product } />
            <Route exact path="/login" component={ Login } />
            <Route exact path="/register" component={ Register } />
            <Route exact path="/cart" render={() => (
                isLoggedIn ? (
                    <Cart/>
                ) : (
                    <Redirect to="/"/>
                )
            )}/>
            <Route exact path="/motif" component={ Motif } />
            <Route exact path="/checkout" render={() => (
                isLoggedIn ? (
                    <Checkout/>
                ) : (
                    <Redirect to="/"/>
                )
            )}/>
            <Route exact path="/kristik" component={ Kristik } />
            <Route exact path="/forgotpwd" component={ ForgotPassword } />
            <Route exact path="/resetpwd/:email/:token" component={ ResetPassword } />
            <Route exact path="/account" render={() => (
                isLoggedIn ? (
                    <Account/>
                ) : (
                    <Redirect to="/"/>
                )
            )}/>
            <Route exact path="/account/order" render={() => (
                isLoggedIn
                    ? ( <Order/>)
                    : ( <Redirect to="/"/> )
            )}/>
            <Route exact path="/account/settings" render={() => (
                isLoggedIn
                    ? ( <Settings/>)
                    : ( <Redirect to="/"/> )
            )}/>
            <Route exact path="/account/update" render={() => (
                isLoggedIn ? (
                    <AccountUpdate/>
                ) : (
                    <Redirect to="/"/>
                )
            )}/>
            <Route exact path="/ditenun" component={ Ditenun } />
            <Route exact path="*" component={ NotFound } />
        </Switch>
    </BrowserRouter>
)

export default Router;
