import React from 'react';
import {
    Container,
    Row, Col,
    Media, Jumbotron
} from 'reactstrap';
import Spinner from '..//components/common/Spinner';
import { connect } from 'react-redux';
import { allProfile} from '../actions/profile';

class ProfilePenenun extends React.Component{
    constructor(props){
        super(props);

        this.state = {
            loading: props.loading,
            profilePenenun: [],
        };
    }

    componentWillMount() {
        const { dispatch } = this.props;

        dispatch(allProfile());
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            loading: nextProps.loading,
        });
    }

    renderProfilePenenunList(){
        if(this.props.profilePenenun == null){
            return (
                <Jumbotron>
                    <h1 className="display-3">Tidak ada profile penenun</h1>
                </Jumbotron>
            );
        }

        return this.props.profilePenenun.map((profile, i) => {
            return(
                <Col key={i} className="Profile-column" lg="4" sm="4" md="4" xs="4">
                    <Media className="Profile-content">
                        <a href={`${profile.id}`} className="d-block mb-4 h-100" idProfile={profile.id}>
                            <img className="img-fluid" src={profile.image_profile} alt={profile.name} />
                            <p>{profile.name}</p>
                        </a>
                    </Media>
                </Col>
            )
        })
    }

    render(){
        console.log('loading: ', this.state.loading);
        return(
            <div className="Ditenun-profile">
                <section>
                    <Container className="Profile-list">
                        <Row>
                            { this.renderProfilePenenunList() }
                            { this.state.loading
                                ? <Spinner />
                                : ''
                            }
                        </Row>
                    </Container>
                </section>
            </div>
        );
    }
}

const mapStateToProps = ({ profile, user }) => {
    return {
        isLoggedIn: user.isLoggedIn,
        profilePenenun: profile.profilePenenun,
        loading: profile.loading
    }
};

export default connect(mapStateToProps)(ProfilePenenun);
