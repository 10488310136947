/**
 * @module Sagas/User
 * @desc User
 */
import { call, put, select } from 'redux-saga/effects';

import AddressAPI from '../utils/api/address';
import * as types from '../constants/address';
import { handleError } from '../actions/app';
import { getCustomerAddress as getAddress } from '../actions/address';

/**
 * Address List
 */
export function* getCustomerAddress({ param }) {
    try {
        let { detail } = yield select(state => state.user);

        const result = yield call(AddressAPI.all, detail.id, detail.api_token);

        yield put({
            type: types.GET_CUSTOMER_ADDRESS_SUCCESS,
            payload: result,
        });
    } catch (err) {
        yield put(handleError(err));

        yield put({
            type: types.GET_CUSTOMER_ADDRESS_FAILURE,
            payload: err,
        });
    }
}

/**
 * Add New Address
 */
export function* addCustomerAddress({ payload }) {
    try {
        let { detail } = yield select(state => state.user);

        const result = yield call(AddressAPI.add, detail.id, payload, detail.api_token);

        yield put({
            type: types.ADD_CUSTOMER_ADDRESS_SUCCESS,
            payload: result,
        });

        yield put(getAddress(detail.id));

    } catch (err) {
        yield put({
            type: types.ADD_CUSTOMER_ADDRESS_FAILURE,
            payload: err,
        });
    }
}
