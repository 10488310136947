import React, { Component }  from "react";
import {
    Button,
    Media,
    Row,
    Col,
    Collapse,
    ListGroup,
    ListGroupItem
} from 'reactstrap';
import {
    ExpandMore,
    ExpandLess
} from '@material-ui/icons';
import PropTypes from 'prop-types';
// import DateTime from 'luxon/src/datetime.js'
import currency from 'currency.js';

class OrderItem extends Component {
    constructor(props) {
        super(props);

        this.state = {
            item:  props.item,
            collapsed: props.item.payment_status_id === 2
        };

        this.collapse = this.collapse.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            item:  nextProps.item,
            collapsed: nextProps.item.payment_status_id === 2
        });
    }

    collapse() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }

    render() {
        const { item, collapsed } = this.state;

        return (
            <div>
                <Media className="List-cart">
                    <Media body>
                        <Row>
                            <Col md="8">
                                <span><strong>Order #{item.id}</strong></span>
                                <div>{ item.created_at }</div>
                            </Col>
                            <Col md="4" className="text-right">
                                { item.payment_status_id === 2
                                    ? (
                                        <button type="button"
                                            onClick={() => this.props.confirm(item)}
                                            className="btn btn-primary btn-sm"
                                        >Konfirmasi</button>
                                    )
                                    : (
                                        <div>
                                            <div>{item.paymentstatus.description}</div>
                                            <Button color="link" onClick={this.collapse}>
                                                <span>Detail</span>
                                                { collapsed
                                                    ? (<ExpandLess />)
                                                    : (<ExpandMore />)
                                                }
                                            </Button>
                                        </div>
                                    )
                                }
                            </Col>
                        </Row>
                        <Row className="order-detail">
                            <Collapse isOpen={collapsed}>
                                <ListGroup className="list-solid text-left">
                                    { item.cart.detail_cart.map((cart, i) => {
                                        return (
                                            <ListGroupItem key={i}>
                                                <Media>
                                                    <Media left href="#">
                                                        {/* <Media object src={'http://api.ditenun.com'+cart.product.thumbnail} alt={cart.product.name} /> */}
                                                        <Media object src={cart.product.thumbnail} alt={cart.product.name} />
                                                    </Media>
                                                    <Media body className="description text-left">
                                                        <Media heading>
                                                          {cart.product.name}
                                                        </Media>
                                                        <ListGroup>
                                                            <ListGroupItem>
                                                                <span className="cart-price">
                                                                    Rp {currency(cart.product.price[0].price, { symbol: 'Rp', separator: '.', decimal: ',', precision: 0}).format()}
                                                                </span>
                                                            </ListGroupItem>
                                                            <ListGroupItem>
                                                                <span className="cart-desc">
                                                                Jumlah: { cart.quantity }
                                                                </span>
                                                            </ListGroupItem>
                                                         </ListGroup>
                                                    </Media>
                                                </Media>
                                            </ListGroupItem>
                                        )
                                    })}
                                </ListGroup>
                                <div className="total-order">
                                    Total: <span className="pull-right">Rp {currency(item.total_price, { symbol: 'Rp', separator: '.', decimal: ',', precision: 0}).format()}</span>
                                </div>
                            </Collapse>
                        </Row>
                    </Media>
                </Media>

            </div>
        );
    }
}

OrderItem.propTypes = {
    item: PropTypes.object,
    confirm: PropTypes.func,
};

export default OrderItem;
