import React from 'react';
import { Container, Row, Col, Media } from 'reactstrap';

class Mobile extends React.Component{
    render() {
        return(
            <div className="Mobile">
                <section>
                    <Container>
                        <Row>
                            <Col sm="6" md="6">
                                <Media href="/pattern">
                                    <img className="Jumbotron-image" src="/images/mobile.png" alt="okosad"/>
                                </Media>
                            </Col>
                            <Col sm="6" md="6" className="Jumbotron-description">
                                <h1>Ciptakan motifmu sendiri</h1>
                                <p>DiTenun merupakan kolaborasi multidisiplin antara kesenian dan keterampilan tradisional menenun yang digabungkan dengan kecanggihan teknologi yang dapat membantu memudahkan proses desain dan mengembangkan desain tenun. Dalam penelitian ini, berbagai bidang ilmu yaitu matematika-geometri, sains computer (Machine learning dan pattern recognition) dan seni (baik berupa audio maupun visual) dikaji mendalam guna menghasilkan piranti cerdas berbasis mobile.</p>
                                <a className="google" href="https://play.google.com/store/apps/details?id=com.EtnicBag.Sewuka" target="blank"><img className="Jumbotron-image" src="/images/googleplay.png" alt="okosad"/></a>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </div>
        );
    }
}

export default Mobile;