/**
 * @module Actions/Address
 * @desc Address Actions
 */

import * as types from '../constants/address';

export const getCustomerAddress = (customerId) => {
    return {
      type: types.GET_CUSTOMER_ADDRESS,
      params: {
          customer_id: customerId
      }
    }
};

export const addCustomerAddress = (payload) => {
    return {
      type: types.ADD_CUSTOMER_ADDRESS,
      payload: payload,
    }
};
