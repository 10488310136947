import React from 'react';
import { connect } from 'react-redux';
import {
    Container,
    Modal,
    ModalBody,
    ModalHeader,
} from 'reactstrap';
import GenerateM from '../components/motif/GenerateM';
import RandomMotif from '../components/motif/Random';
import UploadMotif from '../components/motif/Import';
import { generateMotif, getAllMotif } from '../actions/generator';
import '../components/css/Upload.css';

class Motif extends React.Component{
    constructor(props){
        super(props);

        this.state = {
            image: props.image,
            modalOpened: false,
            uploading: props.uploading,
        }
        this.imageChange = this.imageChange.bind(this);
        this.toggleModalUpload = this.toggleModalUpload.bind(this);
        this.handleUploadMotif = this.handleUploadMotif.bind(this);
    }

    componentWillMount() {
        const { dispatch } = this.props;
            
        dispatch(getAllMotif());  
    }

    componentWillReceiveProps(nextProps) {
        if (!nextProps.uploading) {
            this.setState({
                uploading: nextProps.uploading
            });
        } else {
            const { dispatch } = this.props;
            dispatch(getAllMotif());
        }
    }

    toggleModalUpload() {
        this.setState({
            modalOpened: !this.state.modalOpened
        });
    }

    handleUploadMotif(file, images) {
        this.setState({
            image: images[0],
            modalOpened: false,
            uploading: true,
        });

        const { dispatch } = this.props;
        
        dispatch(generateMotif(file[0]));
    }

    imageChange(){
        if (this.props.isLoggedIn) {
            this.toggleModalUpload();
        } else {
            var randomItem = this.props.images[Math.floor(Math.random()*this.props.images.length)];
            this.setState({image: randomItem})
        }   
    }

    render(){
        return(
            <Container className="Center">
                { this.props.isLoggedIn
                    ? (
                        <GenerateM
                            image={this.state.image}
                            generated={this.props.generated}
                            list={this.props.motif}
                            imageChange={this.imageChange}
                            uploading={this.state.uploading}        
                        />
                    )
                    : (
                        <RandomMotif
                            imageChange={this.imageChange}
                            image={this.state.image}
                        />
                    )
                }
                
                <Modal isOpen={this.state.modalOpened} toggle={this.toggleModalUpload} size="md" className="modal-address">
                    <ModalHeader>Generate Motif</ModalHeader>
                    <ModalBody className="Ditenun Ditenun-modal">
                        <UploadMotif handleUpload={this.handleUploadMotif}/>
                    </ModalBody>
                </Modal>
                
            </Container>
        );
    } 
}

Motif.defaultProps = {
    image: "/images/motif/1.jpg",
    images: [
        "/images/motif/1.jpg", 
        "/images/motif/2.jpg", 
        "/images/motif/3.jpg", 
        "/images/motif/4.jpg",
        "/images/motif/5.jpg"
    ]
};

const mapStateToProps = ({ user, generator }) => {
    return {
      isLoggedIn: user.isLoggedIn,
      generated: generator.generated,
      uploading: generator.generating,
      motif: generator.motif
    }
};

export default connect(mapStateToProps)(Motif);