import update from 'immutability-helper';
import * as types from '../constants/generator';

const initialState = {
    generating: false,
    generated: '',
    kristik: [],
    motif: [],
}

// Handles user related actions
export default function (state = initialState, action) {
    switch (action.type) {
        case types.GENERATE_KRISTIK:
            return {
                ...state,
                generating: true,
            };

        case types.GENERATE_MOTIF:
            return {
                ...state,
                generating: true,
            };

        case types.GENERATE_KRISTIK_SUCCESS:
            return {
                ...state,
                generating: false,
                generated: action.payload.data.url,
                kristik: update(state.kristik, { $unshift: [action.payload.data] })
            };

        case types.GENERATE_MOTIF_SUCCESS:
            return {
                ...state,
                generating: false,
                generated: action.payload.data.url,
                motif: update(state.motif, { $unshift: [action.payload.data] })
            };

        case types.GET_ALL_KRISTIK_SUCCESS:
            return {
                ...state,
                kristik: [
                    ...action.payload.data.data
                ]
            };

        case types.GET_ALL_MOTIF_SUCCESS:
            return {
                ...state,
                motif: [
                    ...action.payload.data.data
                ]
            };    

        case types.GENERATE_KRISTIK_FAILURE:
            return {
                ...state,
                generating: false,
            };

            case types.GENERATE_MOTIF_FAILURE:
            return {
                ...state,
                generating: false,
            };    

        default:
            return state;
    }
}
