/**
 * @module Actions/User
 * @desc User Actions
 */

import * as types from '../constants/user';

export const login = (email, password) => {
    return {
        type: types.USER_LOGIN,
        payload: { email, password },
    }
};

export const logout = () => ({
    type: types.USER_LOGOUT
});

export const register = (data) => ({
    type: types.USER_REGISTER,
    payload: data,
});

export const resetPassword = (data) => ({
    type: types.USER_RESET_PASSWORD,
    payload: data,
});

export const updateProfile = (data) => ({
    type: types.USER_UPDATE_PROFILE,
    payload: data,
});
