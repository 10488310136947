import request from './request';

export default {
    login(email, password) {
        const path = `auth/login`;
        const method = 'post';

        const payload = { email, password };

        return request.process(method, path, payload);
    },

    register(data) {
        const path = `auth/signup`;
        const method = 'post';

        const payload = {
            password: data.password,
            email: data.email,
            firstname: data.firstname,
            username: data.email,
            lastname: data.lastname,
            address: '',
            phone: data.phone,
        };

        return request.process(method, path, payload);
    },

    changePassword(data, token) {
        const path = `auth/resetPassword`;
        const method = 'post';

        const payload = {
            password: data.password,
            password_confirmation : data.password_confirmation,
            email: data.email,
            token: token,
        };

        return request.process(method, path, payload, token);
    },

    updateProfile(customerId, data, token) {
        const path = `customer/${customerId}`;
        const method = 'put';

        const payload = Object.assign({}, data, {
            name: `${data.firstname} ${data.lastname}`
        });

        return request.process(method, path, payload, token);
    }
};
