import axios from 'axios';
import request from './request';
import { API_URL } from '../../Config';

export default {
    generateKristik(data, token) {
        const path = `kristik`;

        const formData = new FormData();

        formData.append("square_size", data.square_size);
        formData.append("color_amount", data.color_amount);
        formData.append("img_file", data.file);

        return axios.request({
            url: `${API_URL}/${path}`,
            method: 'POST',
            data: formData,
            // timeout: 1500,
            headers: {
              'Content-Type': 'multipart/form-data',
              token: token,
            }
        })
        .catch((error) => {
            if (error.response) {
                return Promise.reject({
                    message: error.response.data.error,
                    code: error.response.status
                });
            } else if (error.request) {
              // The request was made but no response was received
              // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
              // http.ClientRequest in node.js
              console.log(error.request);
              throw error;
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log('Error', error.message);
              throw error;
            }
        });
    },

    generateMotif(data, token) {
        var path = `motif`;

        var formData = new FormData();

        formData.append("matrix", data.matrix);
        formData.append("color", data.color);
        formData.append("img_file", data.file);

        return axios.post(`${API_URL}/${path}`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
              token: token,
            },
            params: {
                token: token,
            },
            
        })
        .catch((error) => {
            if (error.response) {
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
                return Promise.reject({
                    message: error.response.data.error,
                    code: error.response.status
                });
            } else if (error.request) {
              // The request was made but no response was received
              // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
              // http.ClientRequest in node.js
              // The request was made but no response was received
              console.log(error.request);
              throw error;
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log('Error', error.message);
            //   console.log(error.response.data);
              throw error;
            }
        });
    },

    getAllKristik(token) {
        const path = `kristik`;
        const method = 'get';

        return request.process(method, path, null, token);
    },

    getAllMotif(token) {
        const path = `motif`;
        const method = 'GET';

        return request.process(method, path, null, token);
      },
};
