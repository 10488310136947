import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import Carousel from './Carousel';


class Jumbotron extends React.Component{
    render() {
        return(
            <div className="Jumbotron">
                <section>
                    <Container>
                        <Row>
                            <Col sm="6" md="6" className="Mobile-hidden">
                                <h1>Ditenun Piranti cerdas penghasil motif tenun nusantara</h1>
                                <p>
                                    Misi kami adalah mengembangkan motif tenun Nusantara, melestarikan budaya Indonesia dan membuatnya dapat dengan mudah diakses secara universal.<br />
                                    Komitmen kami meningkatkan kesejahteraan masyarakat perajin kain tenun melalui keterampilan tradisional berdasarkan teknologi.
                                </p>
                                <p>
                                    DiTenun merupakan inovasi baru yang berasal dari kebutuhan nyata bahwa motif tenun perlu dikembangkan.
                                </p>
                                <Row>
                                    <a className="link1" href="/motif" >Generate Motif</a>
                                    <a className="link2" href="/kristik" >Generate Kristik</a>
                                </Row>
                            </Col>
                            <Col sm="6" className="Mobile-hidden">
                                <Carousel />
                            </Col>
                        </Row>
                    </Container>
                </section>
            </div>
        );
    }
}

export default Jumbotron;