/**
 * @namespace Constants
 * @desc Address constants
 */

export const GET_CUSTOMER_ADDRESS = 'GET_CUSTOMER_ADDRESS';
export const GET_CUSTOMER_ADDRESS_SUCCESS = 'GET_CUSTOMER_ADDRESS_SUCCESS';
export const GET_CUSTOMER_ADDRESS_FAILURE = 'GET_CUSTOMER_ADDRESS_FAILURE';

export const ADD_CUSTOMER_ADDRESS = 'ADD_CUSTOMER_ADDRESS';
export const ADD_CUSTOMER_ADDRESS_SUCCESS = 'ADD_CUSTOMER_ADDRESS_SUCCESS';
export const ADD_CUSTOMER_ADDRESS_FAILURE = 'ADD_CUSTOMER_ADDRESS_FAILURE';
