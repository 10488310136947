/**
 * @module Actions/Generator
 * @desc Generator Actions
 */

import * as types from '../constants/generator';

export const generateKristik = (file, size=2, color=2) => {
    return {
        type: types.GENERATE_KRISTIK,
        payload: {
            file: file,
            square_size: size,
            color_amount: color,
        },
    }
};

export const generateMotif = (file, matrix=2, color=1) => {
    return {
        type: types.GENERATE_MOTIF,
        payload: {
            file: file,
            matrix: matrix,
            color: color,
        },
    }
};

export const getAllKristik = () => {
    return {
        type: types.GET_ALL_KRISTIK
    }
};

export const getAllMotif = () => {
    return {
        type: types.GET_ALL_MOTIF
    }
}
