import React from 'react';
import {
  Container, Col, Form,
  FormGroup, Input,
  Button, FormFeedback,
} from 'reactstrap';
import '../css/ForgotPassword.css';
import { API_URL } from '../../Config';
import axios from 'axios';
import { Link } from 'react-router-dom';

class ForgotPassword extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            'email': '',
            
	        validate: {
	            emailState: '',
	        },

	        redirect : false
            
        }
        this.handleChange = this.handleChange.bind(this);
        this.submitForm = this.submitForm.bind(this);
    }

    validateEmail(e) {
        const emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const { validate } = this.state
            if (emailRex.test(e.target.value)) {
                validate.emailState = 'has-success'
            } else {
                validate.emailState = 'has-danger'
            }
            this.setState({ validate })
    } 

    handleChange = async (event) => {
        const { target } = event;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const { name } = target;
        await this.setState({
            [ name ]: value,
        });
    }

   submitForm(e) {
        e.preventDefault();
   
        var data={
            "email":this.state.email,
        }

        axios.post(API_URL + '/auth/sendEmail', data)
        .then(function (response) {
            if(response.status === 200){
                console.log("succes");
                alert("succes, konfirmasi sudah dikirimkan ke email")
            }
            else if(response.status === 204){
                console.log("forgot password failed");
                alert("forgot password failed")
            }
            else{
                console.log("forgot password failed");
                alert("forgot password failed");
            }
        })
        .catch(function (error) {
            console.log(error);
    	});
   	}

    render(){
    	const { email} = this.state;
        return(
            <div>
            	<section>
	            	<Container className="ForgotPassword">
	            		<h3 className="ForgotPassword-title">Forgot Password</h3>
	            		<Form className="form" onSubmit={ (e) => this.submitForm(e) }>
	                        <Col>
	                            <FormGroup>
	                            <Input
	                                type="email"
	                                name="email"
	                                id="Email"
	                                placeholder="Masukkan Alamat Email"
	                                value={ email }
	                                valid={ this.state.validate.emailState === 'has-success' }
	                                invalid={ this.state.validate.emailState === 'has-danger' }
	                                onChange={ (e) => {
	                                    this.validateEmail(e)
	                                    this.handleChange(e)
	                                } }
	                            />
	                            <FormFeedback valid>
	                                {/* Test berhasil */}
	                            </FormFeedback>
	                            <FormFeedback>
	                                Email salah
	                            </FormFeedback>
	                            {/* <FormText></FormText> */}
	                            </FormGroup>
	                        </Col>
	                        <Col>
	                        	<Button> KIRIM </Button>
	                        </Col>
	                        <Col>
	                        	<p>Kembali ke halaman <Link to={'/login'}> login </Link> atau <Link to={'/register'}> daftar </Link> </p>
	                        </Col>
	                    </Form>
	            	</Container>
	            </section>
            </div>
        );
    }
}

export default ForgotPassword;
