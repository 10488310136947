import React from 'react';
import "../css/HomePage.css";
import Jumbotron from './Jumbotron';
import Work from './Work';
import Service from './Service';
import Motif from './Motif';
import Mobile from './Mobile';
import Kristik from './Kristik';
// import Testimony from './Testimony';
// import Newsletter from './Newsletter';

class HomePage extends React.Component{
    render() {
        return(
            <div>
                <Jumbotron />
                <Work />
                <Motif />
                <Kristik />
                <Mobile />
                <Service />
                {/* <Testimony /> */}
                {/* <Newsletter /> */}
            </div>
        );
    }
}

export default HomePage;