import * as types from '../constants/address';

const initialState = {
    address: null,
}

// Handles user related actions
export default function (state = initialState, action) {
    switch (action.type) {
        case types.GET_CUSTOMER_ADDRESS_SUCCESS:
            return {
                ...state,
                address: action.payload.message !== null
                    ? action.payload.message[0]
                    : null,
            };

        default:
            return state;
    }
}

