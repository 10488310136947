// export const API_URL = 'http://178.128.106.214:8000/api/v1';
// export const API_URL = 'http://api.ditenun.com:8000/api/v1';
export const API_URL = 'http://api.ditenun.com';
// export const API_URL = 'http://localhost:8000';
// export const API_MOBILE = 'http://localhost:9000';
// export const API_MOBILE_SERVER = 'http://mobile.ditenun.com/api';
// export const API_URL = 'http://localhost:8000/api/v1';
// export const BASE_URL = 'http://178.128.106.214:8000';
// export const BASE_URL = 'http://localhost:3000';
export const BASE_URL = 'http://www.ditenun.com';

