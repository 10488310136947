import React, { Component } from 'react';
import {
    Button,
    Row,
    Col,
    Form,
    FormGroup,
    FormFeedback,
    Label,
    Input
} from 'reactstrap';
import PropTypes from 'prop-types';
import validator from 'validator';
import pick from 'lodash.pick';
import FormValidator from '../../../utils/FormValidator';

class RegisterForm extends Component {
    constructor(props) {
        super(props);

        this.validator = new FormValidator([
            {
                field: 'firstname',
                method: validator.isEmpty,
                validWhen: false,
                message: 'Silahkan isi Nama Depan'
            },
            {
                field: 'firstname',
                method: validator.isLength,
                args: [{ max: 50 }],
                validWhen: true,
                message: 'Nama Depan maksimal 50 karakter'
            },
            {
                field: 'lastname',
                method: validator.isEmpty,
                validWhen: false,
                message: 'Silahkan isi Nama Belakang'
            },
            {
                field: 'lastname',
                method: validator.isLength,
                args: [{ max: 50 }],
                validWhen: true,
                message: 'Nama Belakang maksimal 50 karakter'
            },
            {
                field: 'email',
                method: validator.isEmpty,
                validWhen: false,
                message: 'Silahkan isi email'
            },
            {
                field: 'email',
                method: validator.isEmail,
                validWhen: true,
                message: 'Silahkan isi format email yang benar'
            },
            {
                field: 'password',
                method: validator.isEmpty,
                validWhen: false,
                message: 'Silahkan isi password'
            },
            {
                field: 'confirm_password',
                method: validator.isEmpty,
                validWhen: false,
                message: 'Silahkan isi data konfirmasi password'
            },
            {
                field: 'confirm_password',
                method: this.passwordMatch,   // notice that we are passing a custom function here
                validWhen: true,
                message: 'Password dan Konfirmasi Password tidak sama'
            },
            {
                field: 'phone',
                method: validator.isEmpty,
                validWhen: false,
                message: 'Silahkan isi Nomor Handphone'
            },
        ]);

        this.state = {
            firstname: '',
            lastname: '',
            email: '',
            password: '',
            confirm_password: '',
            phone: '',
            validation: this.validator.valid()
        };

        this.register = this.register.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        this.setState({[event.target.name]: event.target.value});
    }

    passwordMatch = (confirmation, state) => (state.password === confirmation)

    register() {
        const { handleRegister } = this.props;
        const validation = this.validator.validate(this.state);

        this.setState({
            validation: validation
        });

        if (validation.isValid) {
            const payload = pick(this.state, ['firstname', 'lastname', 'email', 'password','phone']);

            handleRegister(payload);
        }
    };

    render() {
        const { validation } = this.state;

        return (
            <Row>
                <Col sm="12">
                    <h1>formulir pendaftaran</h1>
                    <Form className="Left">
                        <FormGroup>
                            <Label for="firstname">Nama Depan</Label>
                            <Input invalid={validation.firstname.isInvalid} type="text" name="firstname" id="register_firstname" placeholder="Nama Depan" onChange={this.handleChange} value={this.state.firstname} />
                            <FormFeedback>{validation.firstname.message}</FormFeedback>
                        </FormGroup>
                        <FormGroup>
                            <Label for="lastname">Nama Belakang</Label>
                            <Input invalid={validation.lastname.isInvalid} type="text" name="lastname" id="register_lastname" placeholder="Nama Belakang" onChange={this.handleChange} value={this.state.lastname} />
                            <FormFeedback>{validation.lastname.message}</FormFeedback>
                        </FormGroup>
                        <FormGroup>
                            <Label for="email">Email</Label>
                            <Input invalid={validation.email.isInvalid} type="email" name="email" id="register_email" placeholder="example@ditenun.com" onChange={this.handleChange} value={this.state.email} />
                            <FormFeedback>{validation.email.message}</FormFeedback>
                        </FormGroup>
                        <FormGroup>
                            <Label for="password">Password</Label>
                            <Input type="password" name="password" id="register_password" placeholder="password" onChange={this.handleChange} invalid={validation.password.isInvalid} value={this.state.password} />
                            <FormFeedback>{validation.password.message}</FormFeedback>
                        </FormGroup>
                        <FormGroup>
                            <Label for="password">Konfirmasi Password</Label>
                            <Input type="password" name="confirm_password" id="register_confirm_password" placeholder="confirm password" onChange={this.handleChange} invalid={validation.confirm_password.isInvalid} value={this.state.confirm_password} />
                            <FormFeedback>{validation.confirm_password.message}</FormFeedback>
                        </FormGroup>
                        <FormGroup>
                            <Label for="phone">Phone</Label>
                            <Input type="number" name="phone" id="phone" placeholder="+628" onChange={this.handleChange} invalid={validation.phone.isInvalid} value={this.state.phone} />
                            <FormFeedback>{validation.phone.message}</FormFeedback>
                        </FormGroup>
                    </Form>
                    <Button onClick={this.register}>Daftar</Button>
                </Col>
            </Row>
        )
    }
}

RegisterForm.propTypes = {
    handleRegister: PropTypes.func.isRequired,
  };

export default RegisterForm;
