import React from 'react';
import { connect } from 'react-redux';
import {
    Container,
    Col,
    Row
} from 'reactstrap';
import SideMenu from '../components/account/SideMenu';
import { logout } from '../actions/user';
import { getAllKristik } from '../actions/generator';

class Account extends React.Component{
    constructor(props) {
        super(props);

        this.state = {};

        this.logout = this.logout.bind(this);
    }

    componentWillMount() {
        const { dispatch } = this.props;

        dispatch(getAllKristik());
    }

    logout() {
        const { dispatch } = this.props;

        dispatch(logout());
    }

    render() {
        return(
            <Container className="Content-wrapper">
                <div className="Ditenun-list-cart">
                    <h1>Update Profile</h1>
                    <Row>
                        <Col xs="12" sm="4">
                            <SideMenu user={this.props.user} handleLogout={this.logout}/>
                        </Col>
                        <Col xs="12" sm="8">
                                <div className="List-cart">
                                    <h5>Kristik</h5>
                                    <hr />
                                    { this.props.kristik.map((item, i) => (
                                        <img key={i} className="generate" src={item.url} alt="selected motif"/>
                                    ))}
                                </div>
                                <hr />
                                <div className="List-cart">
                                    <h5>Motif</h5>
                                    <hr />
                                    <img className="generate" src="/images/motif/1.jpg" alt="motif 1" />
                                    <img className="generate" src="/images/motif/2.jpg" alt="motif 2" />
                                    <img className="generate" src="/images/motif/3.jpg" alt="motif 3" />
                                    <img className="generate" src="/images/motif/4.jpg" alt="motif 4" />
                                    <img className="generate" src="/images/motif/5.jpg" alt="motif 5" />
                                </div>
                        </Col>
                    </Row>
                </div>
            </Container>
        );
    }
}

const mapStateToProps = ({ user, order, generator }) => {
    return {
      isLoggedIn: user.isLoggedIn,
      user: user.detail,
      kristik: generator.kristik
    }
};

export default connect(mapStateToProps)(Account);
