import React from 'react'
import {
     Col,
     Row,
     Button,
} from 'reactstrap';
import AddPhotoAlternate from '@material-ui/icons/AddPhotoAlternate';
import Spinner from '../common/Spinner';
import PropTypes from 'prop-types';

const GenerateKristik = (props) => {
    // console.log(props.list);
    // console.log(typeof props.list);
    return (
        <Row>
            <Col md={6}>
                <div className="box box-solid upload">
                    <div className="box-body">
                        { props.uploading
                            ? (
                                <div>
                                    <img src={props.image} alt="upload placeholder"/>
                                    <Spinner />
                                </div>
                            )
                            : props.generated !== ''
                                ? <img src={props.generated} alt="generated kristik"/>
                                : <AddPhotoAlternate className="icon-large"/>
                        }
                    </div>
                    <div className="box-footer">
                        <Button className="button3" disabled={props.uploading} onClick={props.imageChange}>
                            Generate Kristik dari photo
                        </Button>
                    </div>
                </div>
            </Col>
            <Col md={6}>
                <div className="box box-solid list">
                    <div className="box-header">
                        <h4>Kristik yang sudah di generate</h4>
                    </div>
                    <div className="box-body">
                        <Row>
                            { props.list.map((item, i) => {
                                return (
                                    <Col xs="12" md="6" key={i}>
                                        <img src={item.url} alt={item.name}/>
                                    </Col>
                                )
                            })
                            }
                        </Row>
                    </div>
                </div>
            </Col>
        </Row>
    );
}

GenerateKristik.propTypes = {
    image: PropTypes.string.isRequired,
    generated: PropTypes.string,
    list: PropTypes.array,
    uploading: PropTypes.bool.isRequired,
    imageChange: PropTypes.func.isRequired,
};

export default GenerateKristik;
