import request from './request';

export default {
    all(customerId, token) {
        const path = `address/getcustomeraddress/${customerId}`;
        const method = 'get';

        return request.process(method, path, null, token);
    },

    add(customerId, payload, token) {
        const path = `address`;
        const method = 'post';

        const address = {
            customer_id: customerId,
            description: `${payload.nama} ${payload.phone}, ${payload.alamat} ${payload.district} ${payload.zip}`
        }

        return request.process(method, path, address, token);
    },
};
