import { createStore, applyMiddleware, compose } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import rootReducer from '../reducers';
import rootSaga from '../sagas';
import middleware, { sagaMiddleware } from './middleware';

const reducer = persistReducer(
  {
    key: 'ditenun', // key is required
    storage, // storage is now required
    whitelist: ['user', 'cart'],
  },
  rootReducer,
);

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

//  Returns the store instance
// It can  also take initialState argument when provided
const configureStore = () => {

    const store = createStore(reducer, composeEnhancer(applyMiddleware(...middleware)));

    sagaMiddleware.run(rootSaga);

    return {
        persistor: persistStore(store),
        store,
    };
};

const { store, persistor } = configureStore();

export { store, persistor };
