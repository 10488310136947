import React from 'react';
import { Container, Row, Col, Media } from 'reactstrap';

class Motif extends React.Component{
    render() {
        return(
            <div className="Mobile-hidden Tab-hidden">
                <section>
                    <Container>
                        <Row>
                            <Col xs="6">
                                <Media href="/pattern">
                                    <img className="Jumbotron-image" src="/images/IMG_1971.jpg" alt="okosad"/>
                                </Media>
                            </Col>
                            <Col xs="6" className="Motif-description Ditenun">
                                <h1>Kualitas prima</h1>
                                <p>
                                    Kami di sini untuk meningkatkan cara konsumen mengakses mode khususnya kain tenun dan membawa perspektif baru ke kehidupan yang berkelanjutan. Kami memilih langsung kain tenun yang akan dijadikan produk turunan. Kami melakukan pengawasan kualitas yang ketat terhadap setiap produk kami guna untuk mendapatkan hasil yang maksimal.
                                </p>
                                <a className="link1" href="/product">Product Kami</a>
                            </Col>
                        </Row>
                    </Container>
                </section>

            </div>
        );
    }

}

export default Motif;