/**
 * @module Sagas/Profile
 * @desc Product
 */
import { call, put } from 'redux-saga/effects';

import ProfileAPI from '../utils/api/profile';
import * as types from '../constants/profile';

/**
 * Get all profile penenun
 */
// export function* allProfilePenenun() {
//     try {
//         const result = yield call(ProductAPI.all);

//         yield put({
//             type: types.GET_ALL_PRODUCT_SUCCESS,
//             payload: result,
//         });
//     } catch (err) {
//         yield put({
//             type: types.GET_ALL_PRODUCT_FAILURE,
//             payload: err,
//         });
//     }
// }

/**
 * Get profile penenun by ID
 */
export function* showProfile({ params }) {
    try {
        const result = yield call(ProfileAPI.showProfile, params.profile_id);

        yield put({
            type: types.GET_PROFILE_PENENUN_SUCCESS,
            payload: result,
        });
    } catch (err) {
        yield put({
            type: types.GET_PROFILE_PENENUN_FAILURE,
            payload: err,
        });
    }
}

export function* allProfile() {
    try {
        const result = yield call(ProfileAPI.allProfile);

        yield put({
            type: types.GET_ALL_PROFILE_PENENUN_SUCCESS,
            payload: result,
        });
    } catch (err) {
        yield put({
            type: types.GET_ALL_PROFILE_PENENUN_FAILURE,
            payload: err,
        });
    }
}
