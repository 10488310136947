import React from 'react';
import { Row, Col } from 'reactstrap';
import OrderItem from './OrderItem';
import PropTypes from 'prop-types';

const OrderList = (props) => {
    return (
        <div className="Ditenun-list-cart">
            <Row>
                <Col>
                    {
                        props.items.map((item, i) => {
                            return (
                                <OrderItem key={i}
                                    item={item}
                                    confirm={props.handleConfirm}
                                />
                            )
                        })
                    }
                </Col>
            </Row>
        </div>
    )
};

OrderList.propTypes = {
    items: PropTypes.array.isRequired,
    handleConfirm: PropTypes.func.isRequired
};

export default OrderList;
