import React from 'react';
import { connect } from 'react-redux';
import {
     Container,
     Modal,
     ModalBody,
     ModalHeader,
 } from 'reactstrap';
import GenerateKristik from '../components/motif/Generate';
import RandomMotif from '../components/motif/Random';
import UploadMotif from '../components/motif/Import';
import { generateKristik, getAllKristik } from '../actions/generator';
import '../components/css/Upload.css';

class Kristik extends React.Component{
    constructor(props) {
        super(props);

        this.state = {
            image: props.image,
            modalOpened: false,
            uploading: props.uploading,
        };

        this.imageChange = this.imageChange.bind(this);
        this.toggleModalUpload = this.toggleModalUpload.bind(this);
        this.handleUpload = this.handleUpload.bind(this);
    }

    componentWillMount() {
        const { dispatch } = this.props;

        dispatch(getAllKristik());
    }

    componentWillReceiveProps(nextProps) {
        if (!nextProps.uploading) {
            this.setState({
                uploading: nextProps.uploading
            });
        } else {
            const { dispatch } = this.props;

            dispatch(getAllKristik());
        }
    }

    toggleModalUpload() {
        this.setState({
            modalOpened: !this.state.modalOpened
        });
    }

    handleUpload(file, images) {
        this.setState({
            image: images[0],
            modalOpened: false,
            uploading: true,
        });

        const { dispatch } = this.props;

        dispatch(generateKristik(file[0]));
    }

    imageChange() {
        if (this.props.isLoggedIn) {
            this.toggleModalUpload();
        } else {
            const image = this.props.images[Math.floor(Math.random()*this.props.images.length)];

            this.setState({image: image})
        }
    }

    render() {
        return(
            <Container className="Center">
                { this.props.isLoggedIn
                    ? (
                        <GenerateKristik
                            image={this.state.image}
                            generated={this.props.generated}
                            list={this.props.kristik}
                            imageChange={this.imageChange}
                            uploading={this.state.uploading}
                        />
                    )
                    : (
                        <RandomMotif
                            imageChange={this.imageChange}
                            image={this.state.image}
                        />
                    )
                }

                <Modal isOpen={this.state.modalOpened} toggle={this.toggleModalUpload} size="md" className="modal-address">
                    <ModalHeader>Generate Kristik</ModalHeader>
                    <ModalBody className="Ditenun Ditenun-modal">
                        <UploadMotif handleUpload={this.handleUpload}/>
                    </ModalBody>
                </Modal>
            </Container>
        );
    }
}

Kristik.defaultProps = {
    image: "/images/kristik/1.png",
    images:  [
        "/images/kristik/1.png",
        "/images/kristik/2.png",
        "/images/kristik/3.png",
        "/images/kristik/4.png",
        "/images/kristik/5.png"
    ]
  };
  
  const mapStateToProps = ({ user, generator }) => {
      return {
        isLoggedIn: user.isLoggedIn,
        generated: generator.generated,
        uploading: generator.generating,
        kristik: generator.kristik
      }
  };

export default connect(mapStateToProps)(Kristik);
