import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Card, CardImg, CardImgOverlay } from 'reactstrap';

class Service extends React.Component{
    render() {
        return(
            <div className="Service">
                <section>
                    <Container>
                        <Row className="Service-title">
                            <Col sm="12" md="12">
                                <h1>Produk Kami</h1>
                            </Col>
                        </Row>
                        <Row className="Service-title">
                            <Col xs="6" sm="4" md="4" className="Service-content">
                                <Card inverse>
                                    <CardImg width="100%" src="/images/souvenir3.png" alt="Card image cap" />
                                    <CardImgOverlay className="text-center d-flex flex-column Ditenun2">
                                        <a className="mt-auto" href="/product">Souvenir</a>
                                    </CardImgOverlay>
                                </Card>
                            </Col>
                            <Col xs="6" sm="4" md="4" className="Service-content">
                                <Card inverse>
                                    <CardImg width="100%" src="/images/fashion3.png" alt="Card image cap" />
                                    <CardImgOverlay className="text-center d-flex flex-column Ditenun2">
                                        <a className="mt-auto" href="/product">Fashion</a>
                                    </CardImgOverlay>
                                </Card>
                            </Col>
                            <Col xs="6" sm="4" md="4" className="Service-content">
                                <Card>
                                    <CardImg width="100%" src="images/comming_soon.png" alt="Card image cap" />
                                    <CardImgOverlay className="text-center d-flex flex-column Ditenun2">
                                        <a className="mt-auto" href="/product">Home Decor</a>
                                    </CardImgOverlay>
                                </Card>
                            </Col>
                            <Col xs="6" sm="4" md="4" className="Service-content">
                                <Card inverse>
                                    <CardImg width="100%" src="images/comming_soon.png" alt="Card image cap" />
                                    <CardImgOverlay className="text-center d-flex flex-column Ditenun2">
                                        <a className="mt-auto" href="/product">Motif Digital</a>
                                    </CardImgOverlay>
                                </Card>
                            </Col>
                            <Col xs="6" sm="4" md="4" className="Service-content">
                                <Card inverse>
                                    <CardImg width="100%" src="images/comming_soon.png" alt="Card image cap" />
                                    <CardImgOverlay className="text-center d-flex flex-column Ditenun2">
                                        <a className="mt-auto" href="/product">Accessories</a>
                                    </CardImgOverlay>
                                </Card>
                            </Col>
                            <Col xs="6" sm="4" md="4" className="Service-content">
                                <Card inverse>
                                    <CardImg width="100%" src="images/comming_soon.png" alt="Card image cap" />
                                    <CardImgOverlay className="text-center d-flex flex-column Ditenun2">
                                        <a className="mt-auto" href="/product">lain-lain</a>
                                    </CardImgOverlay>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </div>
        );
    }
}

export default Service;