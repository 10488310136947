import React from 'react';
import { connect } from 'react-redux';
import {
    Container,
    Col,
    Row,
    Media,
    Modal,
    ModalBody
} from 'reactstrap';
import { Redirect } from 'react-router-dom';
import CheckoutSummary from '../components/checkout/Summary';
import CheckoutAddress from '../components/checkout/Address';
import CheckoutItems from '../components/checkout/Items';
import AddressForm from '../components/checkout/AddressForm';

import { getCustomerAddress, addCustomerAddress } from '../actions/address';
import { processCheckout } from '../actions/cart';
import '../components/css/Modal.css';

class Checkout extends React.Component{
    constructor(props) {
        super(props);

        this.state = {
            modalOpened: false,
        };

        this.processCheckout = this.processCheckout.bind(this);
        this.toggleModalAddAddress = this.toggleModalAddAddress.bind(this);
        this.submitAddress = this.submitAddress.bind(this);
    }

    getTotalPrice() {
        if (!this.props.cart.length) {
            return 0;
        }

        return this.props.cart.reduce((acc, curr) => {
            return acc + (curr.quantity * curr.price);
        }, 0)
    }

    componentWillMount() {
        const { dispatch, user, isLoggedIn } = this.props;

        if (isLoggedIn) {
            dispatch(getCustomerAddress(user.id));
        }
    }

    toggleModalAddAddress() {
        this.setState({
            modalOpened: !this.state.modalOpened
        });
    }

    submitAddress(payload) {
        const { dispatch } = this.props;

        dispatch(addCustomerAddress(payload));

        this.toggleModalAddAddress();
    }

    processCheckout() {
        const { dispatch, cart, address } = this.props;

        if (address !== null && cart.length > 0) {
            const total = this.getTotalPrice();

            dispatch(processCheckout(total));
        }
    }

    render() {
        console.log(this.props.address);
        if (this.props.isCheckout) {
            return <Redirect to="/account/order"/>
        }

        return(
            <Container className="Content-wrapper">
                <Row>
                    <Col xs="12" sm="8" className="checkout cart">
                        <CheckoutAddress
                            address={this.props.address}
                            addAddress={this.toggleModalAddAddress}
                        />
                        <CheckoutItems
                            total={this.getTotalPrice()}
                            items={this.props.cart}
                        />
                        <Media className="box box-solid">
                            <Media body className="box-body">
                                <strong>Note: Pengiriman akan melalui kurir: JNE, TiKi</strong>
                            </Media>
                        </Media>
                    </Col>
                    <Col xs="12" sm="4">
                        <CheckoutSummary total={this.getTotalPrice()}
                            handleCheckout={this.processCheckout} />

                        <Media className="box box-solid">
                            <Media body className="box-body">
                                <Media><strong>Transfer ke Bank: PT. Ditenun</strong></Media>
                                <hr />
                                <Media>Mandiri: 12312.12312.312</Media>
                                <Media>BCA: 12312.12312.312</Media>
                                <Media>BTPN: 12312.12312.312</Media>
                            </Media>
                        </Media>
                    </Col>
                </Row>

                <Modal isOpen={this.state.modalOpened} toggle={this.toggleModalAddAddress} size="md" className="modal-address">
                    <ModalBody className="Ditenun Ditenun-modal">
                        <AddressForm
                            user={this.props.user}
                            handleSubmit={this.submitAddress}
                        />
                    </ModalBody>
                </Modal>
            </Container>
        );
    }
}

const mapStateToProps = ({ user, cart, address }) => {
    return {
      isLoggedIn: user.isLoggedIn,
      user: user.detail,
      cart: cart.cart,
      isCheckout: cart.checkout,
      address: address.address,
    }
};

export default connect(mapStateToProps)(Checkout);
