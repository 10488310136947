import React from 'react';

import FormRegister from './FormRegister';

class Register extends React.Component{

    render(){
        return(
            <div>
                <FormRegister />
            </div>
        );
    }
}

export default Register;
