import React from 'react';
import { UncontrolledCarousel } from 'reactstrap';


const Example = () => <UncontrolledCarousel items={items} />;
const items = [
  {
    src: '/images/IMG_1988.jpg',
    altText: '',
    caption: '',
    header: ''
  },
  {
    src: '/images/IMG_1989.jpg',
    altText: '',
    caption: '',
    header: ''
  },
  {
    src: '/images/IMG_1991.jpg',
    altText: '',
    caption: '',
    header: ''
  }
];

export default Example;