/**
 * @module Sagas/Notification
 * @desc User
 */
import { delay } from 'redux-saga';
import { call, put } from 'redux-saga/effects';

import * as types from '../constants/notification';

export function* clearNotification() {
    yield call(delay, 1000);

    yield put({
      type: types.NOTIFICATION_EMITTED,
    });
}
