// import React, { Component } from "react";
import React  from "react";

const EmptyCart = props => {
  return (
    <div className="empty-cart">
      <img
        src="https://chillydraji.files.wordpress.com/2015/08/empty_cart.jpeg"
        alt="empty-cart"
      />
      <h2>Your cart is empty!</h2>
    </div>
  );
};

export default EmptyCart;
