import React, { Component } from 'react';
import {
    Button,
    Row,
    Col,
    Form,
    FormGroup,
    FormText,
    FormFeedback,
    Label,
    Input
} from 'reactstrap';
import PropTypes from 'prop-types';
import currency from 'currency.js';
import validator from 'validator';
import FormValidator from '../../utils/FormValidator';


class ConfirmOrderForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            files: [],
            images: [],
            notAcceptedFileType: [],
            no_rekening: '',
            nama: '',
            validation: orderConfirmValidation.valid()
        };

        this.handleChange = this.handleChange.bind(this);
        this.confirmOrder = this.confirmOrder.bind(this);
    }

    hasExtension(fileName) {
        const pattern = '(' + this.props.imgExtension.join('|').replace(/\./g, '\\.') + ')$';

        return new RegExp(pattern, 'i').test(fileName);
    }

    readFile(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            // Read the image via FileReader API and save image result in state.
            reader.onload = function (e) {
                // Add the file name to the data URL
                let dataURL = e.target.result;
                dataURL = dataURL.replace(";base64", `;name=${file.name};base64`);
                resolve({file, dataURL});
            };

            reader.readAsDataURL(file);
        });
    }

    handleChange(event) {
        // e.preventDefault();
        if(event.target.name === 'file'){
            const files = event.target.files;
        const allFilePromises = [];
        

        // Iterate over all uploaded files
        for (let i = 0; i < files.length; i++) {
            let file = files[i];

            // Check for file extension
            if (!this.hasExtension(file.name)) {
                const notAcceptedFileType = this.state.notAcceptedFileType.slice();
                notAcceptedFileType.push(file.name);

                this.setState({ notAcceptedFileType });

                continue;
            }

            // Check for file size
            if(file.size > this.props.maxFileSize) {
                const notAcceptedFileSize = this.state.notAcceptedFileSize.slice();
                notAcceptedFileSize.push(file.name);

                this.setState({ notAcceptedFileSize });

                continue;
            }

            allFilePromises.push(this.readFile(file));
        }

        Promise.all(allFilePromises).then(filesImported => {
            const images = this.state.images.slice();
            const files = this.state.files.slice();

            filesImported.forEach(fileImported => {
                images.push(fileImported.dataURL);
                files.push(fileImported.file);
            });
            this.setState({...this.state, images, files});
            console.log(this.state);
        });
        }else{
            this.setState({[event.target.name]: event.target.value});
        }
        
    }

    confirmOrder() {
        const { order, handleSubmit } = this.props;
        const validation = orderConfirmValidation.validate(this.state);

        this.setState({
            validation: validation
        });

        if (validation.isValid) {
            const payload ={file:this.state.files[0], nama:this.state.nama, no_rekening:this.state.no_rekening};

            handleSubmit(order.id, payload);
        }
    }

    render() {
        const { validation } = this.state;

        return (
            <Row>
                <Col sm="12">
                    <div className="box box-solid">
                        <div className="box-body text-left">
                            <div className="cart-price pull left">
                                <h4>Rp {currency(this.props.order.total_price, { symbol: 'Rp', separator: '.', decimal: ',', precision: 0}).format()}</h4>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col sm="12">
                    <div className="box box-solid">
                        <div className="box-header with-border text-left">
                            <h3 className="box-title">Transfer Bank BCA</h3>
                            <div className="box-tools pull-right">
                                <span data-toggle="tooltip" title="" className="badge bg-yellow" data-original-title="3 New Messages">Logo BCA</span>
                            </div>
                        </div>
                        <div className="box-body">
                            <Form className="Left">
                                <FormGroup>
                                    <Label for="no_rekening">No Rekening</Label>
                                    <Input invalid={validation.no_rekening.isInvalid} type="text" name="no_rekening" id="no_rekening" value={this.state.no_rekening} onChange={this.handleChange} />
                                    <FormText color="muted">
                                        Masukkan No Rekening sesuai buku tabungan
                                    </FormText>
                                    <FormFeedback>{validation.no_rekening.message}</FormFeedback>
                                </FormGroup>
                                <FormGroup>
                                    <Label for="nama">Nama Pemilik Rekening</Label>
                                    <Input invalid={validation.nama.isInvalid} type="text" name="nama" id="nama" rows="5" value={this.state.nama} onChange={this.handleChange} />
                                    <FormText color="muted">
                                        Masukkan Nama Pemilik Rekening sesuai buku tabungan
                                    </FormText>
                                    <FormFeedback>{validation.nama.message}</FormFeedback>
                                </FormGroup>
                                <FormGroup>
                                    <Label for="bukti_transfer">Uplaod Bukti Transfer</Label>
                                    <Input type="file"
                                accept={this.props.accept}
                                multiple={this.props.multiple} name="file" id="file" rows="5" onChange={this.handleChange} />
                                    <FormText color="muted">
                                        Size maximal 3 mb
                                    </FormText>
                                    {/* <FormFeedback>{validation.file.message}</FormFeedback> */}
                                </FormGroup>
                            </Form>
                        </div>
                        <div className="box-footer">
                            <FormText color="muted">
                                Jika melalui Teller, isi No Rekening dengan 0000 dan Nama Pemilik Rekening dengan nama penyetor
                            </FormText>
                            <br/>
                            <Button onClick={() => this.confirmOrder() }>Konfirmasi</Button>
                        </div>
                    </div>
                </Col>
            </Row>
        )
    }
}

const orderConfirmValidation = new FormValidator([
    {
      field: 'no_rekening',
      method: validator.isEmpty,
      validWhen: false,
      message: 'Silahkan isi data No Rekening'
    },
    {
      field: 'nama',
      method: validator.isEmpty,
      validWhen: false,
      message: 'Silahkan isi data Nama Pemilik Rekening'
    },
    // {
    //   field: 'file',
    //   method: validator.isEmpty,
    //   validWhen: false,
    //   message: 'Silahkan upload bukti transfer'
    // },
  ]);

ConfirmOrderForm.propTypes = {
  handleSubmit: PropTypes.func,
  order: PropTypes.object,
};

ConfirmOrderForm.defaultProps = {
    accept: "image/*",
    imgExtension: ['.jpg', '.jpeg', '.gif', '.png'],
    maxFileSize: 2097152, // 2MB
    multiple: false,
  };

export default ConfirmOrderForm;
