import request from './request';

export default {
    showProfile(id) {
        const path = `profile-penenun/${id}`;
        const method = 'get';

        return request.process(method, path);
    },

    allProfile() {
        const path = `profile-penenun`;
        const method = 'get';

        return request.process(method, path);
    }
};
