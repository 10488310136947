/**
 * @module Actions/Product
 * @desc PRoduct Actions
 */

import * as types from '../constants/product';

export const allProduct = () => {
    return {
      type: types.GET_ALL_PRODUCT,
    }
};


export const category = (id) => ({
    type: types.GET_PRODUCT_BY_CATEGORY,
    params: {
        category_id: id,
    }
});

export const detail = (id) => ({
    type: types.GET_PRODUCT_DETAIL,
    params: {
        product_id: id,
    }
});

export const images = (id) => ({
    type: types.GET_PRODUCT_IMAGES,
    params: {
        product_id: id,
    }
});

export const addToCart = (product) => ({
    type: types.ADD_PRODUCT_TO_CART,
    payload: {
        product: product,
    }
});
