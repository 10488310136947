import * as types from '../constants/notification';
import * as userTypes from '../constants/user';

const initialState = {}

// Handles user related actions
export default function (state = initialState, action) {
    switch (action.type) {
        case types.EMIT_NOTIFICATION:
            return {
                ...state,
                ...action.payload
            };

        case types.NOTIFICATION_EMITTED:
            return initialState;

        case userTypes.USER_LOGOUT_SUCCESS:
            return initialState;

        default:
            return state;
    }
}
