import React from 'react';
import { connect } from 'react-redux';
import {
    Container,
    Col,
    Row
} from 'reactstrap';
import '../components/css/Box.css';
import '../components/css/Button.css';
import '../components/css/Cart.css';
import CartList from '../components/cart/List';
import CartSummary from '../components/cart/Summary';
import EmptyCart from '../components/cart/EmptyCart';

import {
    increaseQuantity,
    decreaseQuantity,
    remove
} from '../actions/cart';

class Cart extends React.Component{
    constructor(props) {
        super(props);

        this.increaseQuantity = this.increaseQuantity.bind(this);
        this.decreaseQuantity = this.decreaseQuantity.bind(this);
        this.removeProduct = this.removeProduct.bind(this);
    }

    getTotalPrice() {
        if (!this.props.cart.length) {
            return 0;
        }

        return this.props.cart.reduce((acc, curr) => {
            return acc + (curr.quantity * curr.price);
        }, 0)
    }

    increaseQuantity(productId) {
        const { dispatch } = this.props;

        dispatch(increaseQuantity(productId));
    }

    decreaseQuantity(productId) {
        const { dispatch } = this.props;

        dispatch(decreaseQuantity(productId));
    }

    removeProduct(productId) {
        const { dispatch } = this.props;

        dispatch(remove(productId));
    }

    render() {
        return(
            <Container className="Content-wrapper">
                <Row>
                    <Col xs="12" sm="8" className="cart">
                        {
                            this.props.cart.length > 0
                                ? <CartList
                                    items={this.props.cart}
                                    handleIncrease={this.increaseQuantity}
                                    handleDecrease={this.decreaseQuantity}
                                    handleRemove ={this.removeProduct}
                                />
                                : <EmptyCart />
                        }
                    </Col>
                    <Col xs="12" sm="4">
                        <CartSummary total={this.getTotalPrice()} />
                    </Col>
                </Row>
            </Container>
        );
    }
}

const mapStateToProps = ({ user, cart }) => {
    return {
      isLoggedIn: user.isLoggedIn,
      cart: cart.cart,
    }
};

export default connect(mapStateToProps)(Cart);
