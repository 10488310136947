/**
 * @module Sagas/User
 * @desc User
 */
import { call, put, select } from 'redux-saga/effects';

import AuthAPI from '../utils/api/auth';
import * as types from '../constants/user';
import * as cartTypes from '../constants/cart';
import { showError, showSuccess } from '../actions/notification';

/**
 * Login
 */
export function* login({ payload }) {
    const { email, password } = payload;

    try {
        const result = yield call(AuthAPI.login, email, password);

        yield put({
            type: types.USER_LOGIN_SUCCESS,
            payload: result,
        });

        yield put({
            type: cartTypes.GET_CART_SUMMARY,
        });
    } catch (err) {
        yield put({
            type: types.USER_LOGIN_FAILURE,
            payload: err,
        });

        yield put(showError('Login gagal, pastikan email dan password sudah benar'));
    }
}

/**
 * Logout
 */
export function* logout() {
    try {
        yield put({
            type: types.USER_LOGOUT_SUCCESS,
        });
    } catch (err) {
        yield put({
            type: types.USER_LOGOUT_FAILURE,
            payload: err,
        });

        yield put(showError('Gagal melakukan registrasi, pastikan data yang di masukkan sudah benar'))
    }
}

/**
 * Register
 */
export function* register({ payload }) {
    try {
        const result = yield call(AuthAPI.register, payload);

        yield put({
            type: types.USER_REGISTER_SUCCESS,
            payload: result,
        });
    } catch (err) {
      yield put({
          type: types.USER_REGISTER_FAILURE,
          payload: err,
      });
    }
}

/**
 * Reset Password
 */
export function* resetPassword({ payload }) {
    try {
        let { detail } = yield select(state => state.user);

        const result = yield call(AuthAPI.changePassword, payload, detail.api_token);

        yield put({
            type: types.USER_RESET_PASSWORD_SUCCESS,
            payload: result,
        });

        yield put(showSuccess('Password berhasi di ubah'))
    } catch (err) {
        yield put({
            type: types.USER_RESET_PASSWORD_FAILURE,
            payload: err,
        });

        yield put(showError('Gagal melakukan reset password, coba ulangi lagi.'))
    }
}


/**
 * Update Profile
 */
export function* updateProfile({ payload }) {
    try {
        let { detail } = yield select(state => state.user);

        const result = yield call(AuthAPI.updateProfile, detail.id, payload, detail.api_token);

        yield put({
            type: types.USER_UPDATE_PROFILE_SUCCESS,
            payload: result,
        });

        yield put(showSuccess('Profil anda telah di perbaharui'))
    } catch (err) {
        yield put({
            type: types.USER_UPDATE_PROFILE_FAILURE,
            payload: err,
        });

        yield put(showError('Gagal memperbaharui profile, coba ulangi lagi.'))
    }
}
