import React from 'react';
import {
    Button,
    NavLink
} from 'reactstrap';
import PropTypes from 'prop-types';
import currency from 'currency.js';

const CartSummary = (props) => {
    return (
        <div className="box box-solid">
            <div className="box-header with-border text-left">
                <h3 className="box-title">Ringkasan Belanja</h3>
            </div>

            <div className="box-body text-left">
                <ul className="list-group list-group-unbordered">
                    <li className="list-group-item">
                      Total Harga <span className="pull-right">Rp {currency(props.total, { symbol: 'Rp', separator: '.', decimal: ',', precision: 0 }).format()}</span>
                    </li>
                 </ul>
            </div>

            <div className="box-footer clearfix no-border">
                <NavLink href="/checkout">
                  <Button color="primary">Checkout</Button>
                </NavLink>
            </div>
        </div>
    )
};

CartSummary.propTypes = {
    total: PropTypes.number.isRequired,
  };

export default CartSummary;
