import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { API_URL } from '../../Config';
import '../css/Login.css';
import { Container, Row, Col } from 'reactstrap';

class ResetPassword extends Component{
    constructor(props){
        super(props);
        this.state = {
            email: this.props.match.params.email,
            password: '',
            password_confirmation :'',
            token:this.props.match.params.token,
            redirectTo: false,
            error: ''
        };
        this.reset = this.reset.bind(this);
        this.onChange = this.onChange.bind(this);
    }
    onChange(e){
        this.setState({[e.target.name]: e.target.value});
    }
    reset(){
        console.log('State', this.state);
        if(this.state.email && this.state.password && this.state.password_confirmation){
            this.PostData('/auth/resetPassword', this.state)
            .then((result) => {
                console.log('reset password', result);
                let responseJson = result;
                if(responseJson.token){
                    sessionStorage.setItem('userData', JSON.stringify(responseJson));
                    this.setState({redirectToReferrer: true});
                    console.log('Session', sessionStorage.getItem('userData'));
                }
            });
        }
        
    }
    PostData(type, userData) {
        console.log('Data for post', userData);
        console.log('url ', API_URL+type);
        let BaseURL = API_URL;
        return new Promise((resolve, reject) =>{
            fetch(BaseURL+type, {
                method: 'POST',
                headers: { 
                    'Accept': 'application/json',
                    'Content-Type': 'application/json' 
                },
                body: JSON.stringify(userData)
              })
            .then((response) => response.json())
            .then((res) => {
                resolve(res);
                console.log('Callback data', res);
            })
            .catch((error) => {
                console.log('error', error);
                this.setState({error});
                reject(error);
            });
        });
    }
    
    render(){
        if (this.state.redirectTo || sessionStorage.getItem('userData')){
            return (<Redirect to={'/'}/>)
        }
        return(
            <div>
                <section>
                    <Container>
                        <Row className="Login">
                            <Col sm="12" md={{ size: 6, offset: 3}}>
                                <h2>Masukkan Kata Sandi Baru</h2>
                                <label>Email</label>
                                <input type="text" name="email" value= {this.state.email} disabled ></input>
                                <label>Password</label>
                                <input type="password" name="password" placeholder="enter your password" onChange={this.onChange} ></input>
                                <label>Confirm Password</label>
                                <input type="password" name="password_confirmation" placeholder="enter your confirm password" onChange={this.onChange} ></input>
                                <input type="hidden" name="token" value={this.state.token} ></input>
                                <button type="submit" value="Login" onClick={this.reset}>Ubah Kata Sandi</button>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </div>
        );
    }
}

export default ResetPassword;
